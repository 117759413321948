import { Injectable } from '@angular/core';


import { __values } from 'tslib';
import { LoginSessionData } from './model';

@Injectable({ providedIn: 'root' })

export class UserService {
    constructor() { }
    isAdmin: boolean = false

    getUserRole(): string {
        const session: LoginSessionData = JSON.parse(sessionStorage.getItem('cb-user-session')!)
        const role: string = session['user']['ROLE']
        return role
    }

    setUserRole() {
        this.isAdmin = this.getUserRole().toUpperCase() == "ADMIN"
    }
    
    IsAdmin(): boolean {
        return this.getUserRole().toUpperCase() == "ADMIN"
    }
}