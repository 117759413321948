<div class="component-header">
  <div class="component-title">
    PANELS
  </div>
  <div class="component-search-bar"  *ngIf="this.userService.IsAdmin()">
    <p-button 
      class="add-panel-btn" 
      label="Add Panel" 
      (onClick)="showAddPanelDialog()">
    </p-button>
    <span class="p-input-icon-right">
      <i class="pi pi-search" style="color: #367B88"></i>
      <input type="text" pInputText />
    </span>
  </div>
</div>
<div class="component-data">
  <p-table [columns]="cols" [value]="tableData" styleClass="p-datatable-gridlines"
    [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <ng-container *ngIf="col.field === 'ACTIONS' && this.userService.IsAdmin()">
            <div class="icon-container">
              <i *ngFor="let iconClass of rowData.ACTIONS;let i=index" (click)="actionClick(i,rowData)"
                class="{{ iconClass }}" style="cursor: pointer;"></i>
            </div>
          </ng-container>
          <ng-container *ngIf="col.field !== 'ACTIONS'">
            {{ rowData[col.field] }}
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]='cols.length'>No records found.</td>
      </tr>
  </ng-template>
  </p-table>
</div>

<p-dialog header="Add Panel" *ngIf="isShowAddPanel " [(visible)]="isShowAddPanel" [style]="{width: '30vw'}" [draggable]="false" >
  <div class="p-fluid">
    <form [formGroup]="addPanelForm" (ngSubmit)="onSubmit()">
      <div class="p-field">
        <label for="panelName">Name</label>
        <input id="panelName" type="text" pInputText formControlName="panelName" />
      </div>
      <div class="p-field">
        <label for="panelEmail">Email</label>
        <input id="panelEmail" type="email" pInputText formControlName="panelEmail" />
      </div>
      <div class="p-field">
        <label for="panelMobile">Phone</label>
        <input id="panelMobile" type="phone" pInputText formControlName="panelMobile" />
      </div>
      <p-button type="submit" label="Submit" [disabled]="!addPanelForm.valid" [style]="{width: '5vw'}"></p-button>
    </form>
  </div>
</p-dialog>

<p-dialog header="Delete Panel" *ngIf="isShowDeletePanel" [(visible)]="isShowDeletePanel" [style]="{width: '30vw'}" [draggable]="false">
  <div class="p-fluid">
    <form (ngSubmit)="deletePanel()">
      <div style="margin-bottom: 20px;">
        <label for="panelName">Are you sure you want to delete {{emailToDelete}}?</label>
      </div>
      <p-button type="submit" label="Delete"  [style]="{width: '5vw',margin:'0 20px 0 0'}"></p-button>
      <p-button type="button" label="Cancel" (click)="dismissDeletePanel()"  class="p-cancel-btn"></p-button>
    </form>
  </div>
</p-dialog>

<p-dialog header="Edit Panel" *ngIf="isShowEditPanel" [(visible)]="isShowEditPanel" [style]="{width: '30vw'}" [draggable]="false">
  <div class="p-fluid">
    <form [formGroup]="editPanelForm" (ngSubmit)="updatePanel()">
      <div class="p-field">
        <label for="panelName">Name</label>
        <input id="panelName" type="text" pInputText formControlName="panelName" />
        <div [ngStyle]="{color: 'red', 'margin-top': '5px'}" *ngIf="editPanelForm.controls['panelName'].errors?.['required']">
          Please enter name
        </div>
      </div>
      <div class="p-field">
        <label for="panelEmail">Email</label>
        <input id="panelEmail" type="email" pInputText formControlName="panelEmail" readonly [style]="{background: '#dddddd'}"/>
      </div>
      <div class="p-field">
        <label for="panelMobile">Mobile</label>
        <input id="panelMobile" type="text" pInputText formControlName="panelMobile" />
      </div>
      <p-button type="submit" label="Update" [disabled]="!editPanelForm.valid" [style]="{width: '5vw',margin:'0 20px 0 0'}"></p-button>
      <p-button type="button" label="Cancel" (click)="dismissEditPanel()"  class="p-cancel-btn"></p-button>
    </form>
  </div>
</p-dialog>