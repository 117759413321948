import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthenticationService } from '../shared/authentication.service';
import { LoginRequest, LoginSessionData } from '../shared/model';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { ToastService } from '../shared/toast/toast.service';
import { SessionService } from '../shared/session.service';
import { UserService } from '../shared/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  form!: UntypedFormGroup;
  loginSub!: Subscription;
  resetPasswordUrl: string = environment.RESET_PASSWORD;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private spinnerService: SpinnerService,
    private notificationService: ToastService,
    private sessionService: SessionService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.initForm();
    this.spinnerService.hideSpinner();
  }

  ngOnDestroy(): void {
    this.loginSub?.unsubscribe();
  }

  initForm(): void {
    this.form = this.fb.group({
      username: [
        '',
        []
      ],
      password: [
        '',
        []
      ],
    });
  }

  createLoginPayload(): LoginRequest {
    const loginPayload = {
      'username': this.form.value.username,
      'password': this.form.value.password
    }
    return loginPayload;
  }

  checkForSubmit(event: KeyboardEvent) {

    if (event.key == 'Enter') {
      this.onSubmit()
    }
  }

  onSubmit(): void {
    this.spinnerService.showSpinner();
    const loginPayload: LoginRequest = this.createLoginPayload();
    this.loginSub = this.authenticationService.login(loginPayload).subscribe({
      next: (data: LoginSessionData) => this.handleLoginResponse(data),
      error: () => {
        this.spinnerService.hideSpinner();
        this.notificationService.showMessage('Invalid credentials', 'error');
      }
    });
  }

  handleLoginResponse(data: LoginSessionData): void {
    if (data?.success) {
      this.sessionService.setSessionObject(data);
      this.sessionService.updateAuthStatus(true);
      this.sessionService.isLoggedIn();
      this.userService.setUserRole();
      this.router.navigate(['drives']);
    } else {
      this.notificationService.showMessage('Invalid credentials', 'error');
    }
    this.spinnerService.hideSpinner();
  }
}