import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CbApiService } from 'src/app/shared/cbapi.service';
import { AddDrivePanelRequest, AddDrivePanelResponse, ApiResponse, DeleteCommonReponse, DrivePanelDetails, DrivePanelList, GetDrivePanelListResponse, GetPanelListResponse, InterviewPanelDetails, PanelDetails, ProcessedDrivePanel, UpdateDrivePanelRequest } from 'src/app/shared/model';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-drive-panels',
  templateUrl: './drive-panels.component.html',
  styleUrls: ['./drive-panels.component.scss']
})
export class DrivePanelsComponent {
  addPanelForm!: UntypedFormGroup;
  updatePanelForm!: UntypedFormGroup;

  isShowAddPanel: boolean = false;
  isShowUpdatePanel: boolean = false;
  isShowDeleteDrivePanel: boolean = false;

  deleteDrivePanelId: string = '';
  driveId: string = '';

  masterPanelList: SelectItem[] = [];
  firstPanelList: SelectItem[] = [] ;
  secondPanelList: SelectItem[] = [] ;

  getPanelSub!: Subscription;
  addDrivePanelSub!: Subscription;
  updateDrivePanelSub!: Subscription;
  deleteDrivePanelSub!: Subscription;

  interviewPanelDetails: InterviewPanelDetails[] = [];
  interviewTypes: SelectItem<string>[] = [
    {
      label: "Interview 1", value: "INT_1"
    }, 
    {
      label: "Interview 2", value: "INT_2"
    },
    {
      label: "Managerial Interview", value: "INT_M"
    }
  ]

  cols = [
    {
        field: 'ID',
        header: 'Panel Id'
    },
    {
        field: 'FIRST_PANELIST_NAME',
        header: 'First Panelist'
    },
    {
        field: 'SECOND_PANELIST_NAME',
        header: 'Second Panelist'
    },
    {
      field: 'actions',
      header: 'Actions'
    }
  ];

  interview: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder, 
    private cbApiService: CbApiService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    public userService:UserService
    ) {}

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    this.getDriveId();
    this.getDrivePanelList();
    this.getPanelData();
    this.initAddPanelForm();
  }

  initAddPanelForm(): void {
    this.addPanelForm = this.fb.group({
      firstPanelist: [
        '',
        [Validators.required]
      ],
      secondPanelist: [
        '',
        []
      ],
      firstInterview: [
        false,
        []
      ],
      secondInterview: [
        false,
        []
      ],
      managerialInterview: [
        false,
        []
      ]
    });

    this.firstPanelList = [...this.masterPanelList];
    this.secondPanelList = [...this.firstPanelList];
  }

  initUpdatePanelForm(rowData: ProcessedDrivePanel): void {
    let first_panelist = this.masterPanelList.find(panel => panel.value == rowData.FIRST_PANELIST_NAME);
    let second_panelist = this.masterPanelList.find(panel => panel.value == rowData.SECOND_PANELIST_NAME);
    this.updatePanelForm = this.fb.group({
      firstPanelist: [
        first_panelist?.value,
        [Validators.required]
      ],
      secondPanelist: [
        second_panelist?.value,
        []
      ],
      firstInterview: [
        rowData.INTERVIEW_TYPE == "INT_1",
        []
      ],
      secondInterview: [
        rowData.INTERVIEW_TYPE == "INT_2",
        []
      ],
      managerialInterview: [
        rowData.INTERVIEW_TYPE == "INT_M",
        []
      ],
      id: [
        rowData.ID,
        []
      ]
    });
  }

  onFirstPanelSelected(event: any): void {
    this.secondPanelList = this.masterPanelList.filter(x => x.value != event.value)
  }

  onSecondPanelSelected(event: any): void {
    this.firstPanelList = this.masterPanelList.filter(x => x.value != event.value)
  }

  getDriveId(): void {
    this.route.parent?.params.subscribe(params => {
      this.driveId = params['driveId'];
    });
  }

  getPanelData(): void {
    this.getPanelSub = this.cbApiService.getPanelList().subscribe({
      next: (data: any) => {
        this.mapPanelList(data);
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch panel list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  mapPanelList(data: GetPanelListResponse): void {
    if (data.success) {
      let panelDetails = data.body as PanelDetails[];
      panelDetails.forEach( (panel) => {
        this.masterPanelList?.push({label: panel.NAME!, value: panel.EMAIL!})
      });
      this.firstPanelList = [...this.masterPanelList];
      this.secondPanelList = [...this.firstPanelList];
    }
  }

  getDrivePanelList(): void {
    this.getPanelSub = this.cbApiService.getDrivePanelList(this.driveId).subscribe({
      next: (data: any) => {
        this.mapDrivePanelList(data);
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch panel list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  mapDrivePanelList(data: GetDrivePanelListResponse): void {
    if (data.success) {
      let drivePanelList = data.body as DrivePanelList;
      this.interviewPanelDetails = []

      if (drivePanelList.INTERVIEW1_PANEL_LIST){
        drivePanelList.INTERVIEW1_PANEL_LIST.forEach((panel) => {
            this.interviewPanelDetails?.push({
              ID: panel.TYPE,
              INTERVIEW_TYPE: "INT_1", 
              FIRST_PANELIST_NAME: panel.INTERVIEWER1_EMAIL, 
              SECOND_PANELIST_NAME: panel.INTERVIEWER2_EMAIL,
            })
          }
        );
      }

      if (drivePanelList.INTERVIEW2_PANEL_LIST){
        drivePanelList.INTERVIEW2_PANEL_LIST.forEach((panel) => {
            this.interviewPanelDetails?.push({
              ID: panel.TYPE,
              INTERVIEW_TYPE: "INT_2", 
              FIRST_PANELIST_NAME: panel.INTERVIEWER1_EMAIL, 
              SECOND_PANELIST_NAME: panel.INTERVIEWER2_EMAIL
            })
          }
        );
      }

      if (drivePanelList.INTERVIEWM_PANEL_LIST){
        drivePanelList.INTERVIEWM_PANEL_LIST.forEach((panel) => {
            this.interviewPanelDetails?.push({
              ID: panel.TYPE,
              INTERVIEW_TYPE: "INT_M", 
              FIRST_PANELIST_NAME: panel.INTERVIEWER1_EMAIL, 
              SECOND_PANELIST_NAME: panel.INTERVIEWER2_EMAIL,
            })
          }
        );
      }
    }
  }

  getPanelsByInterviewType(type: string) {
    return this.interviewPanelDetails.filter(panel => panel.INTERVIEW_TYPE == type);
  }

  showAddPanelDialog(): void {
    this.isShowAddPanel = true;
  }

  onAddPanelFormSubmit(): void {
    this.spinnerService.showSpinner();

    if (!this.addPanelForm.value.firstInterview && 
        !this.addPanelForm.value.secondInterview &&
        !this.addPanelForm.value.managerialInterview){
      this.toastService.showMessage('No interview type selected.', 'error');
      this.spinnerService.hideSpinner();
      return;
    }

    const addDrivePanelRequest: AddDrivePanelRequest = {
      INTERVIEWER1_EMAIL: this.addPanelForm.value.firstPanelist,
      IS_INTERVIEW1_PANEL: this.addPanelForm.value.firstInterview,
      IS_INTERVIEW2_PANEL: this.addPanelForm.value.secondInterview,
      INTERVIEWER2_EMAIL: this.addPanelForm.value.secondPanelist,
      IS_INTERVIEWM_PANEL: this.addPanelForm.value.managerialInterview
    };
    
    this.addDrivePanelSub = this.cbApiService.addDrivePanel(addDrivePanelRequest, this.driveId)
    .subscribe({
      next: (data: AddDrivePanelResponse) => {
        if (data?.success) {
          this.toastService.showMessage('Successfully added panel', 'success');
          this.getDrivePanelList();
          this.initAddPanelForm();
          this.isShowAddPanel = false;
        } else {
          if(data?.message == 'Duplicate Interviewer'){
            this.toastService.showMessage('Interviewer already exists in a panel', 'error')
          }
          else{
            this.toastService.showMessage('Failed to add panel', 'error');
          }
        }
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to add panel', 'error');
      }
    });
  }

  showUpdatePanelDialog(rowData: ProcessedDrivePanel): void {
    this.initUpdatePanelForm(rowData);
    this.isShowUpdatePanel = true;
  }

  onUpdatePanelFormSubmit(): void {
    
    if (!this.updatePanelForm.value.firstInterview && 
        !this.updatePanelForm.value.secondInterview &&
        !this.updatePanelForm.value.managerialInterview){
      this.toastService.showMessage('No interview type selected.', 'error');
      this.spinnerService.hideSpinner();
      return;
    }

    const updateDrivePanelRequest: UpdateDrivePanelRequest = {
      INTERVIEWER1_EMAIL: this.updatePanelForm.value.firstPanelist,
      INTERVIEWER2_EMAIL: this.updatePanelForm.value.secondPanelist,
      IS_INTERVIEW1_PANEL: this.updatePanelForm.value.firstInterview,
      IS_INTERVIEW2_PANEL: this.updatePanelForm.value.secondInterview,
      IS_INTERVIEWM_PANEL: this.updatePanelForm.value.managerialInterview
    };
    this.spinnerService.showSpinner();
    this.updatePanel(updateDrivePanelRequest, this.updatePanelForm.value.id);
    
  }

  updatePanel(updateDrivePanelRequest: UpdateDrivePanelRequest, panelId: string): void {
    this.updateDrivePanelSub = this.cbApiService.updateDrivePanel(updateDrivePanelRequest, this.driveId, panelId)
    .subscribe({
      next: (data: ApiResponse) => this.handleUpdateDriveResponse(data),
      error: () => {
        this.spinnerService.hideSpinner();
        this.isShowUpdatePanel = false;
        this.toastService.showMessage('Failed to update drive', 'error');
      }
    });
  }

  handleUpdateDriveResponse(data: ApiResponse): void {
    if (data?.success) {
      this.toastService.showMessage('Successfully updated the panel', 'success');
      this.getDrivePanelList();
    } else {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Failed to update panel', 'error');
    }
    this.isShowUpdatePanel = false;
  }

  showDeletePanelDialog(rowData: ProcessedDrivePanel): void {
    this.deleteDrivePanelId = rowData.ID!;
    this.isShowDeleteDrivePanel = true;
  }

  dismissDeleteDrivePanel(): void {
    this.isShowDeleteDrivePanel = false;
  }

  deleteDrivePanel(): void {
    this.spinnerService.showSpinner();
    this.deleteDrivePanelSub = this.cbApiService.deleteDrivePanel(this.driveId, this.deleteDrivePanelId).subscribe({
      next: (data: DeleteCommonReponse) => {
        this.isShowDeleteDrivePanel = false;
        this.getDrivePanelList();
        this.toastService.showMessage('Successfully deleted the panel', 'success');
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.toastService.showMessage('Failed to delete panel', 'error');
        this.spinnerService.hideSpinner();
      }
    })
  }

  ngOnDestroy(): void {
    this.getPanelSub?.unsubscribe();
    this.addDrivePanelSub?.unsubscribe();
    this.updateDrivePanelSub?.unsubscribe();
    this.deleteDrivePanelSub?.unsubscribe();
  }
}
