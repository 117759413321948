import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { Message } from 'primeng/api';


import { CbApiService } from 'src/app/shared/cbapi.service';
import { AddCandidateResponse, CandidateDetails, GetCandidatesListResponse, UpdateCandidateRequest, ApiResponse, UploadBulkCandidatesRequest, GetCandidateDetailsResponse, CandidateInfoToDisplay, CommentDetailToDisplay } from 'src/app/shared/model';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { AddCandidatePayload } from 'src/app/shared/model';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-drive-candidates',
  templateUrl: './drive-candidates.component.html',
  styleUrls: ['./drive-candidates.component.scss']
})
export class DriveCandidatesComponent implements OnInit, OnDestroy {

  isShowAddPanel: boolean = false;
  isAddComponentSelected: boolean = false;
  isShowAddCandidateForm: boolean = false;
  isShowDeleteCandidate: boolean = false;
  isShowCandidateUpdateForm: boolean = false;
  isDisplayCandidateInfo: boolean = false;
  
  driveId: string = '';
  emailToDelete: string = '';

  messages: Message[] = [];
  tableData: any[] = [];
  cols = [
    {
      field: 'SERIALNUMBER',
      header: '#',
      width: '10px'
    },
    {
      field: 'NAME',
      header: 'Candidate Name',
      width: '200px'
    },
    {
      field: 'EMAIL',
      header: 'Candidate Email',
      width: '200px'
    },
    {
      field: 'PHONE',
      header: 'Candidate Mobile',
      width: '200px'
    },
    {
      field: 'ROLLNO',
      header: 'Roll Number',
      width: '200px'
    },
    {
      field: 'RESUME',
      header: 'Resume',
      width: '200px'
    },
    {
      field: 'APTISCORE',
      header: 'Aptitude Score',
      width: '200px'
    },
    {
      field: 'PROGSCORE',
      header: 'Programming Score',
      width: '200px'
    },
    {
      field: 'TOTALSCORE',
      header: 'Total Score',
      width: '200px'
    },
    {
      field: 'MAXSCORE',
      header: 'Maximum Score',
      width: '200px'
    },
    {
      field: 'STATUS',
      header: 'Status',
      width: '200px'
    },
    {
      field: 'LOCATION',
      header: 'Location',
      width: '200px'
    },
    {
      field: 'GRADYEAR',
      header: 'GradYear',
      width: '200px'
    },
    {
      field: 'BRANCH',
      header: 'Branch',
      width: '200px'
    },
    {
      field: 'DEGREE',
      header: 'Degree',
      width: '200px'
    },
    {
      field: 'COLLEGE',
      header: 'College',
      width: '200px'
    },
    {
      field: 'ACTIONS',
      header: 'Actions',
      width: '200px'
    }
  ];

  addCandidateForm!: UntypedFormGroup;
  updateCandidateForm!: UntypedFormGroup;

  addCandidateSub!: Subscription;
  getCandidatesListSub!: Subscription;
  updateCandidateSub!: Subscription;
  deleteCandidateSub!: Subscription;
  getCandidateSub!: Subscription;
  currentCandidateToDisplay!: CandidateInfoToDisplay;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private cbApiService: CbApiService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    public userService:UserService) {}

  ngOnInit(): void {
    this.getDriveId();
    this.initAddCandidateForm();
    this.getCandidatesList();
  }

  ngOnDestroy(): void {
    this.addCandidateSub?.unsubscribe();
    this.getCandidatesListSub?.unsubscribe();
    this.updateCandidateSub?.unsubscribe();
    this.deleteCandidateSub?.unsubscribe();
    this.getCandidateSub?.unsubscribe();
  }

  initAddCandidateForm(): void {
    this.addCandidateForm = this.fb.group({
      name: [''],
      email: [
        null,
        []
      ],
      phone: [
        null,
        []
      ],
      resume: [
        null,
        []
      ],
      degree: [
        '',
        []
      ],
      branch: [
        '',
        []
      ],
      location: [
        '',
        []
      ],
      college: [
        '',
        []
      ],
      status: [
        'PROFILE_SEL',
        []
      ],
      rollnumber: [
        '',
        []
      ],
      gradyear: [
        '',
        []
      ],
      totalscore: [
        '',
        []
      ],
      maxscore: [
        '',
        []
      ],
      progscore: [
        '',
        []
      ],
      aptiscore: [
        '',
        []
      ],
      comment: ['']
    });
  }

  initUpdateCandidateForm(rowData: CandidateDetails): void {
    this.updateCandidateForm = this.fb.group({
      name: [
        rowData.NAME,
        []
      ],
      email: [
        rowData.EMAIL,
        []
      ],
      phone: [
        rowData.PHONE,
        []
      ],
      resume: [
        rowData.RESUME,
        []
      ],
      degree: [
        rowData.DEGREE,
        []
      ],
      branch: [
        rowData.BRANCH,
        []
      ],
      location: [
        rowData.LOCATION,
        []
      ],
      college: [
        rowData.COLLEGE,
        []
      ],
      status: [
        rowData.STATUS,
        []
      ],
      rollnumber: [
        rowData.ROLLNO,
        []
      ],
      gradyear: [
        rowData.GRADYEAR,
        []
      ],
      totalscore: [
        rowData.TOTALSCORE,
        []
      ],
      maxscore: [
        rowData.MAXSCORE,
        []
      ],
      progscore: [
        rowData.PROGSCORE,
        []
      ],
      aptiscore: [
        rowData.APTISCORE,
        []
      ],
      comment: [
        rowData.COMMENT
      ]
    });
  }

  getDriveId(): void {
    this.route.parent?.params.subscribe(params => {
      this.driveId = params['driveId'];
    });
  }

  addCandidate(): void {
    this.isShowAddCandidateForm = true;
  }

  onAddCandidateFormSubmit(): void {
    this.spinnerService.showSpinner();
    const addCandidatePayload: AddCandidatePayload = this.createAddCandidatePayload();
    this.addCandidateSub = this.cbApiService.addCandidate(this.driveId, addCandidatePayload)
    .subscribe({
      next: (data: AddCandidateResponse) => this.handleAddCandidateResponse(data),
      error: () => {
        this.spinnerService.hideSpinner();
        this.isAddComponentSelected = false;
        this.isShowAddCandidateForm = false;
        this.toastService.showMessage('Failed to add candidate', 'error');
      }
    });
  }

  handleAddCandidateResponse(data: AddCandidateResponse): void {
    if (data?.success) {
      this.getCandidatesList();
      this.toastService.showMessage('Successfully added the candidate', 'success');
    } else {
      this.toastService.showMessage('Failed to add candidate', 'error');
    }
    this.isAddComponentSelected = false;
    this.isShowAddCandidateForm = false;
    this.addCandidateForm.reset();
    this.spinnerService.hideSpinner();
  }

  createAddCandidatePayload(): AddCandidatePayload {
    const addCandidatePayload: AddCandidatePayload = {
      NAME: this.addCandidateForm.value.name,
      EMAIL: this.addCandidateForm.value.email,
      PHONE: this.addCandidateForm.value.phone,
      RESUME: this.addCandidateForm.value.resume,
      DEGREE: this.addCandidateForm.value.degree,
      BRANCH: this.addCandidateForm.value.branch,
      GRADYEAR: this.addCandidateForm.value.gradyear,
      TOTALSCORE: this.addCandidateForm.value.totalscore,
      MAXSCORE: this.addCandidateForm.value.maxscore,
      PROGSCORE: this.addCandidateForm.value.progscore,
      APTISCORE: this.addCandidateForm.value.aptiscore,
      COMMENT: this.addCandidateForm.value.comment,
      ROLLNO: this.addCandidateForm.value.rollnumber,
      LOCATION: this.addCandidateForm.value.location,
      COLLEGE: this.addCandidateForm.value.college,
      STATUS: this.addCandidateForm.value.status
    }

    return addCandidatePayload;
  }


  showAddPanelDialog(): void {
    this.isShowAddPanel = true;
  }

  enableAddCandidateComponent(): void {
    this.isAddComponentSelected = true;
  }

  disableAddCandidateComponent(): void {
    this.isAddComponentSelected = false;
  }

  onFileSelect(event: any, fileUpload: any): void {
    this.spinnerService.showSpinner();
    let fileToUpload: File = event.files[0];
    
    if(!environment.ALLOWED_FILE_TYPES_FOR_BULK_UPLOAD.includes(fileToUpload.type.toLowerCase())) {
      this.toastService.showMessage('Invalid file uploaded: File extension is not correct', 'error');
      this.spinnerService.hideSpinner();
      fileUpload.clear();
      return;
    }

    const reader = new FileReader();
      reader.onloadend = () => {
          const uploadBulkCandidatesRequest: UploadBulkCandidatesRequest = {
            FILE: reader.result?.toString()
          }
          
          this.cbApiService.uploadBulkCandidates(uploadBulkCandidatesRequest, this.driveId).subscribe({
            next: () => {
              this.messages = [ 
                { 
                  severity: 'success',  
                  summary: 'Candidates report uploaded successfully:',  
                  detail: "It might take some time to have the updated details reflect in below candidate list." 
                }
              ]
              this.spinnerService.hideSpinner();
              this.isAddComponentSelected = false;
            },
            error: () => {
              this.toastService.showMessage('Failed to bulk upload candidates', 'error');
              fileUpload.clear();
              this.spinnerService.hideSpinner();
            }
          })
      };
    reader.readAsDataURL(fileToUpload);
  }

  getCandidatesList(): void {
    this.tableData = [];
    this.spinnerService.showSpinner();
    this.getCandidatesListSub = this.cbApiService.getCandidatesList(this.driveId).subscribe({
      next: (data: GetCandidatesListResponse) => {
        this.mapCandidateslist(data);
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  mapCandidateslist(data: GetCandidatesListResponse): void {
    if (data.success) {
      data.body?.map((candidate, index) => {
        const candidates_list = {
          "PHONE": candidate.PHONE,
          "NAME": candidate.NAME,
          "EMAIL": candidate.EMAIL,
          "ACTIONS": ['pi pi-pencil', 'pi pi-trash', 'pi pi-eye'],
          "SERIALNUMBER": index + 1,
          "ROLLNO": candidate.ROLLNO,
          "RESUME": candidate.RESUME,
          "PROGSCORE": candidate.PROGSCORE,
          "APTISCORE": candidate.APTISCORE,
          "TOTALSCORE": candidate.TOTALSCORE,
          "DEGREE": candidate.DEGREE,
          "BRANCH": candidate.BRANCH,
          "GRADYEAR": candidate.GRADYEAR,
          "STATUS": candidate.STATUS,
          "COLLEGE": candidate.COLLEGE,
          "LOCATION": candidate.LOCATION,
          "MAXSCORE": candidate.MAXSCORE
        }
        this.tableData.push(candidates_list)
      })
    }
  }

  hideDeleteCandidateDialog(): void {
    this.isShowDeleteCandidate = false;
  }

  deleteCandidate(): void {
    if (this.emailToDelete) {
      this.spinnerService.showSpinner();
      this.deleteCandidateSub = this.cbApiService.deleteCandidate(this.driveId, this.emailToDelete)
      .subscribe({
        next: (data: ApiResponse) => this.handleDeleteCandidateResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.isShowDeleteCandidate = false;
          this.toastService.showMessage('Failed to delete candidate', 'error');
        }
      });
    }
  }

  handleDeleteCandidateResponse(data: ApiResponse): void {
    if (data?.success) {
      this.getCandidatesList();
      this.toastService.showMessage('Candidate deleted successfully', 'success');
    } else {
      this.toastService.showMessage('Failed to delete candidate', 'error');
    }
    this.spinnerService.hideSpinner();
    this.isShowDeleteCandidate = false;
  }

  actionClick(event: number, rowData: CandidateDetails): void {
    if (event == 0) {
      this.initUpdateCandidateForm(rowData);
      this.isShowCandidateUpdateForm = true;
    }
    else if (event == 1) {
      this.emailToDelete = rowData.EMAIL!
      this.isShowDeleteCandidate = true;
    }
    else if(event == 2){
      this.displayCandidateInfo(rowData.EMAIL)
    }
  }

  displayCandidateInfo(candidate_email: string) {
    this.spinnerService.showSpinner();

    this.getCandidateSub = this.cbApiService.getCandidateDetails(this.driveId, candidate_email).subscribe({
      next: (data: GetCandidateDetailsResponse) => {
        this.mapCandidateDetails(data);
        this.spinnerService.hideSpinner();
        this.isDisplayCandidateInfo = true
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  mapCandidateDetails(data: GetCandidateDetailsResponse) {
    if (data.success) {
      let candidate = data.body;

      const candidateInfo: CandidateInfoToDisplay = {
        PHONE: candidate.PHONE,
        NAME: candidate.NAME,
        EMAIL: candidate.EMAIL,
        ROLLNO: candidate.ROLLNO,
        RESUME: candidate.RESUME,
        APTISCORE: candidate.APTISCORE,
        TOTALSCORE: candidate.TOTALSCORE,
        DEGREE: candidate.DEGREE,
        BRANCH: candidate.BRANCH,
        STATUS: candidate.STATUS,
        COLLEGE: candidate.COLLEGE,
        DRIVE_ID: candidate.DRIVE_ID,
        CANDIDATE_ID: candidate.CANDIDATE_ID,
        PROGSCORE:candidate.PROGSCORE,
        COMMENTS: []
      }
      candidate.COMMENTS.forEach(comment => {

        let splitted_comment_detail = comment.COMMENT.split(":");
        const comment_to_display: CommentDetailToDisplay = {
          COMMENT_DATE: splitted_comment_detail[0] + ':' + splitted_comment_detail[1] + ':' + splitted_comment_detail[2],
          COMMENT_BY: splitted_comment_detail[3].trim(),
          COMMENT_MESSAGE: splitted_comment_detail.slice(4).join(':').trim()
        }

        candidateInfo.COMMENTS!.push(comment_to_display);
      })
      this.currentCandidateToDisplay = candidateInfo;
    }
  }

  createUpdateCandidatePayload(): UpdateCandidateRequest {
    const updateCandidatePayload = {
      COLLEGE: this.updateCandidateForm.value.college,
      PHONE: this.updateCandidateForm.value.phone,
      GRADYEAR: this.updateCandidateForm.value.gradyear,
      NAME: this.updateCandidateForm.value.name,
      PROGSCORE: this.updateCandidateForm.value.progscore,
      APTISCORE: this.updateCandidateForm.value.aptiscore,
      TOTALSCORE: this.updateCandidateForm.value.totalscore,
      DEGREE: this.updateCandidateForm.value.degree,
      BRANCH: this.updateCandidateForm.value.branch,
      LOCATION: this.updateCandidateForm.value.location,
      MAXSCORE: this.updateCandidateForm.value.maxscore,
      STATUS: this.updateCandidateForm.value.status,
      COMMENT: this.updateCandidateForm?.value.comment,
      RESUME: this.updateCandidateForm.value.resume,
      ROLLNO: this.updateCandidateForm.value.rollnumber
    }
    
    return updateCandidatePayload;
  }

  onUpdateCandidateFormSubmit(): void {
    const updateCandidatePayload = this.createUpdateCandidatePayload();
    this.spinnerService.showSpinner();
    this.updateCandidate(updateCandidatePayload);
  }

  updateCandidate(updateCandidatePayload: UpdateCandidateRequest): void {
    this.updateCandidateSub = this.cbApiService.updateCandidate
    (updateCandidatePayload, this.driveId, this.updateCandidateForm.value.email)
    .subscribe({
      next: (data: ApiResponse) => this.handleUpdateCandidateResponse(data),
      error: () => {
        this.spinnerService.hideSpinner();
        this.isShowCandidateUpdateForm = false;
        this.toastService.showMessage('Failed to update candidate', 'error');
      }
    });
  }

  handleUpdateCandidateResponse(data: ApiResponse): void {
    if (data?.success) {
      this.toastService.showMessage('Successfully updated the candidate', 'success');
      this.getCandidatesList();
    } else {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Failed to update candidate', 'error');
    }
    this.isShowCandidateUpdateForm = false;
  }
}
