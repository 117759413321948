import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLinkActive } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { CbApiService } from 'src/app/shared/cbapi.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-drive-details',
  templateUrl: './drive-details.component.html',
  styleUrls: ['./drive-details.component.scss']
})
export class DriveDetailsComponent implements OnInit {
  displaySideBar: boolean = true;

  sideMenuItems!: MenuItem[];

  driveId: string = '';

  constructor(
    private route: ActivatedRoute,
    private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.getDriveId();
    this.spinnerService.showSpinner();
    this.sideMenuItems = [
      {
          label: 'Summary',
          icon: 'pi pi-fw pi-th-large',
          routerLink: ['/drive-details', this.driveId, 'drive-summary']
      },
      {
          label: 'Candidates',
          icon: 'pi pi-fw pi-users',
          routerLink:  ['/drive-details', this.driveId, 'drive-candidates']
      },
      {
          label: 'Drive Panels',
          icon: 'pi pi-fw pi-user',
          routerLink: ['/drive-details', this.driveId, 'drive-panels']
      },
      {
          label: 'Pipeline',
          icon: 'pi pi-fw pi-file-edit',
          routerLink:  ['/drive-details', this.driveId, 'drive-pipeline']
      },
      {
          label: 'Interviews',
          icon: 'pi pi-fw pi-user-edit',
          routerLink:  ['/drive-details', this.driveId, 'drive-interviews']
      },
      {
          label: 'Selected Candidates',
          icon: 'pi pi-fw pi-id-card',
          routerLink:  ['/drive-details', this.driveId, 'drive-candidates-selected']
      }
    ];
  }

  getDriveId(): void {
    this.route.params.subscribe(params => {
      this.driveId = params['driveId'];
    });
  }
}
