import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { CbApiService } from 'src/app/shared/cbapi.service';
import { ApiResponse, CandidateInfoToDisplay, CommentDetailToDisplay, GetCandidateDetailsResponse, GetCandidatesListResponse, GetStaticDataResponse } from 'src/app/shared/model';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-drive-candidates-selected',
  templateUrl: './drive-candidates-selected.component.html',
  styleUrls: ['./drive-candidates-selected.component.scss']
})
export class DriveCandidatesSelectedComponent implements OnInit, OnDestroy {
  getCandidatesListSub!: Subscription;
  getCandidateSub!: Subscription;
  getStaticDataSub!: Subscription;
  updateCandidateSub!: Subscription;
  
  driveId: string = '';
  isDisplayCandidateInfo: boolean = false;
  currentCandidateEmail: string = '';
  isDisplayCommentsAction: boolean = false;
  textAreaLimit: number = 0;

  commentActionForm!: UntypedFormGroup;

  currentCandidateToDisplay!: CandidateInfoToDisplay;

  tableData: any[] = [];
  cols = [
    {
      field: 'SERIALNUMBER',
      header: '#',
      width: '10px'
    },
    {
      field: 'NAME',
      header: 'Candidate Name',
      width: '200px'
    },
    {
      field: 'EMAIL',
      header: 'Candidate Email',
      width: '200px'
    },
    {
      field: 'PHONE',
      header: 'Candidate Mobile',
      width: '200px'
    },
    {
      field: 'ROLLNO',
      header: 'Roll Number',
      width: '200px'
    },
    {
      field: 'RESUME',
      header: 'Resume',
      width: '200px'
    },
    {
      field: 'APTISCORE',
      header: 'Aptitude Score',
      width: '200px'
    },
    {
      field: 'PROGSCORE',
      header: 'Programming Score',
      width: '200px'
    },
    {
      field: 'TOTALSCORE',
      header: 'Total Score',
      width: '200px'
    },
    {
      field: 'MAXSCORE',
      header: 'Maximum Score',
      width: '200px'
    },
    {
      field: 'STATUS',
      header: 'Status',
      width: '200px'
    },
    {
      field: 'LOCATION',
      header: 'Location',
      width: '200px'
    },
    {
      field: 'GRADYEAR',
      header: 'GradYear',
      width: '200px'
    },
    {
      field: 'BRANCH',
      header: 'Branch',
      width: '200px'
    },
    {
      field: 'DEGREE',
      header: 'Degree',
      width: '200px'
    },
    {
      field: 'COLLEGE',
      header: 'College',
      width: '200px'
    },
    {
      field: 'actions',
      header: 'Actions'
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private cbApiService: CbApiService,
    private spinnerService: SpinnerService,
    private toastService: ToastService) {}

  ngOnInit(): void {
    this.getDriveId();
    this.getCandidatesList();
    this.getPipelineData();
  }

  ngOnDestroy(): void {
    this.getCandidatesListSub?.unsubscribe();
    this.getCandidateSub?.unsubscribe();
    this.getStaticDataSub?.unsubscribe();
    this.updateCandidateSub?.unsubscribe();
  }

  getDriveId(): void {
    this.route.parent?.params.subscribe(params => {
      this.driveId = params['driveId'];
    });
  }

  getCandidatesList(): void {
    this.tableData = [];
    this.spinnerService.showSpinner();
    this.getCandidatesListSub = this.cbApiService.getCandidatesList(this.driveId, 'INT_M_SEL').subscribe({
      next: (data: GetCandidatesListResponse) => {
        this.mapCandidateslist(data);
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  mapCandidateslist(data: GetCandidatesListResponse): void {
    if (data.success) {
      data.body?.map((candidate, index) => {
        const candidates_list = {
          "PHONE": candidate.PHONE,
          "NAME": candidate.NAME,
          "EMAIL": candidate.EMAIL,
          "SERIALNUMBER": index + 1,
          "ROLLNO": candidate.ROLLNO,
          "RESUME": candidate.RESUME,
          "PROGSCORE": candidate.PROGSCORE,
          "APTISCORE": candidate.APTISCORE,
          "TOTALSCORE": candidate.TOTALSCORE,
          "DEGREE": candidate.DEGREE,
          "BRANCH": candidate.BRANCH,
          "GRADYEAR": candidate.GRADYEAR,
          "STATUS": candidate.STATUS,
          "COLLEGE": candidate.COLLEGE,
          "LOCATION": candidate.LOCATION,
          "MAXSCORE": candidate.MAXSCORE,
          "CANDIDATE_ID": candidate.CANDIDATE_ID
        }
        this.tableData.push(candidates_list)
      })
    }
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.tableData);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, " candidates");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  displayCandidateInfo(candidate_email: string) {
    this.spinnerService.showSpinner();

    this.getCandidateSub = this.cbApiService.getCandidateDetails(this.driveId, candidate_email).subscribe({
      next: (data: GetCandidateDetailsResponse) => {
        this.mapCandidateDetails(data);
        this.spinnerService.hideSpinner();
        this.isDisplayCandidateInfo = true
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  mapCandidateDetails(data: GetCandidateDetailsResponse) {
    if (data.success) {
      let candidate = data.body;

      const candidateInfo: CandidateInfoToDisplay = {
        PHONE: candidate.PHONE,
        NAME: candidate.NAME,
        EMAIL: candidate.EMAIL,
        ROLLNO: candidate.ROLLNO,
        RESUME: candidate.RESUME,
        APTISCORE: candidate.APTISCORE,
        TOTALSCORE: candidate.TOTALSCORE,
        DEGREE: candidate.DEGREE,
        BRANCH: candidate.BRANCH,
        STATUS: candidate.STATUS,
        COLLEGE: candidate.COLLEGE,
        DRIVE_ID: candidate.DRIVE_ID,
        CANDIDATE_ID: candidate.CANDIDATE_ID,
        PROGSCORE:candidate.PROGSCORE,
        COMMENTS: []
      }
      candidate.COMMENTS.forEach(comment => {

        let splitted_comment_detail = comment.COMMENT.split(":");
        const comment_to_display: CommentDetailToDisplay = {
          COMMENT_DATE: splitted_comment_detail[0] + ':' + splitted_comment_detail[1] + ':' + splitted_comment_detail[2],
          COMMENT_BY: splitted_comment_detail[3].trim(),
          COMMENT_MESSAGE: splitted_comment_detail.slice(4).join(':').trim()
        }

        candidateInfo.COMMENTS!.push(comment_to_display);
      })
      this.currentCandidateToDisplay = candidateInfo;
    }
  }

  initCommentActionForm(): void {
    this.commentActionForm = this.fb.group({
      comment: [
        '',
        [Validators.required, Validators.maxLength(this.textAreaLimit - 1)]
      ]
    });
  }

  displayaddComments(candidateEmail: string = ''): void {
    this.initCommentActionForm()
    this.currentCandidateEmail = candidateEmail
    this.isDisplayCommentsAction = true
  }

  getPipelineData(): void {
    this.spinnerService.showSpinner()
    this.getStaticDataSub = this.cbApiService.getStaticData()
      .subscribe({
        next: (data: GetStaticDataResponse) => this.handleStaticResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.toastService.showMessage('Failed to fetch static data', 'error');
        }
      });
  }

  handleStaticResponse(data: GetStaticDataResponse): void {
    if (data?.success) {
      this.textAreaLimit = data.body!.TEXT_AREA_LIMIT;
    }
    this.spinnerService.hideSpinner();
  }

  handleUpdateCandidateResponse(data: ApiResponse): void {
    if (data?.success) {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Successfully updated the candidate', 'success');

    } else {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Failed to update candidate', 'error');
    }
    this.isDisplayCommentsAction = false;
  }

  onCommentActionSubmit(): void {
    this.spinnerService.showSpinner();
    var comments = this.commentActionForm.value.comment
    var updateCandidatePayload = {
      "COMMENT": comments
    }
    this.updateCandidateSub = this.cbApiService.addComments
      (updateCandidatePayload, this.driveId, this.currentCandidateEmail)
      .subscribe({
        next: (data: ApiResponse) => this.handleUpdateCandidateResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.isDisplayCommentsAction = false;
          this.toastService.showMessage('Failed to update candidate', 'error');
        }
      });
  }
}