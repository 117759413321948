<div class="sidebar-component-header">
    <div class="component-title">
        Panel list
    </div>
    <div class="component-search-bar">
        <i class="pi pi-refresh" 
            style="color: #367B88; margin-right: 10px; margin-top: 40x; font-size: 17px; cursor: pointer;"
            (click)="refreshInterviewDetails()">
        </i>
        <span class="p-input-icon-right">
            <i class="pi pi-search" style="color: #367B88"></i>
            <input type="text" pInputText />
        </span>
    </div>
</div>

<div>
    <p-accordion (onOpen)='onBaseTabOpen($event)' (onClose)='onBaseTabClose()'>
        <ng-container *ngFor="let interviewType of interviewTypes">
            <p-accordionTab #interviewTypeAccordion [header]="interviewType.label!">
                <ng-template pTemplate="content">
                    <p-accordion (onOpen)='onChildTabOpen($event)' (onClose)='onChildTabClose()'>
                       
                        <ng-container *ngFor="let panel of getInterviewPanelByType(interviewType.value)">
                            <p-accordionTab #panelAccordian [header]="panel.ID! + ' - ' + panel.FIRST_PANELIST_NAME + (panel.SECOND_PANELIST_NAME ? ' & ' + panel.SECOND_PANELIST_NAME : '')" [id]="interviewType.value" >
                                <ng-template pTemplate="content">
                                    <div class="bulk-interview-assignment"
                                    style="display:flex; justify-content: end; margin-bottom: 10px; margin-left: 5px;">
                                    <button style="margin-left: 5px;" type="button" pButton pRipple icon="pi pi-file-excel"
                                      (click)="exportExcel(panel.ID!)" class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                                    <br>
                                  </div>
                                    <p-table [columns]="cols" [value]="currentInterviewListByPanelId"
                                        styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }">
                                        <ng-template pTemplate="header" let-columns>
                                            <tr>
                                                <th *ngFor="let col of columns">
                                                    {{ col.header }}
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData let-columns="columns"
                                            let-rowIndex="rowIndex">
                                            <tr>
                                                <td *ngFor="let col of columns;">
                                                    <ng-container *ngIf="col.field === 'actions'">
                                                        <div class="icon-container">
                                                            <i class="pi pi-step-forward-alt" style="margin-right: 15%;"
                                                                *ngIf="rowData.STATUS !== 'PROFILE_SEL'" pTooltip="Change Status"
                                                                (click)="displayPipelineActionDialog(rowData.STATUS, rowData.EMAIL, rowData.TYPE)"></i>
                                                            <i class="pi pi-comments" style="margin-right: 15%;" pTooltip="Add Comments"
                                                                (click)="displayaddComments( rowData.EMAIL, rowData.TYPE)"></i>
                                                            <i class="pi pi-eye" pTooltip="View Details"
                                                                (click)="displayCandidateInfo(rowData.CANDIDATE_ID)"></i>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="col.field === 'PANEL_ID'">
                                                        {{ rowIndex + 1 }}
                                                    </ng-container>
                                                    <ng-container *ngIf="col.field === 'RESUME'">
                                                        <ul [style]="{'padding-left':'10%'}">
                                                            <li><a style="color:blue" [href]="rowData.RESUME" target="_blank">Resume</a></li>
                                                            <li *ngIf="rowData.INT_1_SCHD_PANEL_HELINK && rowData.INT_1_SCHD_PANEL_HELINK!='' && (rowData.STATUS == 'INT_1_SCHD')"><a style="color:blue" [href]="rowData.INT_1_SCHD_PANEL_HELINK" target="_blank">Interview Link</a></li>
                                                            <li *ngIf="rowData.INT_2_SCHD_PANEL_HELINK && rowData.INT_2_SCHD_PANEL_HELINK!='' && (rowData.STATUS == 'INT_2_SCHD')"><a style="color:blue" [href]="rowData.INT_2_SCHD_PANEL_HELINK" target="_blank">Interview Link</a></li>
                                                            <li *ngIf="rowData.INT_M_SCHD_PANEL_HELINK && rowData.INT_M_SCHD_PANEL_HELINK!='' && (rowData.STATUS == 'INT_M_SCHD')"><a style="color:blue" [href]="rowData.INT_M_SCHD_PANEL_HELINK" target="_blank">Interview Link</a></li>
                                                        </ul>
                                                    </ng-container>
                                                    <ng-container *ngIf="!(['actions', 'RESUME']).includes(col.field)">
                                                        {{ rowData[col.field] }}
                                                    </ng-container>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td [attr.colspan]='cols.length'>No records found.</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </ng-template>
                            </p-accordionTab>
                        </ng-container>
                    </p-accordion>
                </ng-template>
            </p-accordionTab>
        </ng-container>
    </p-accordion>
</div>


<p-dialog header="Action" *ngIf="isDisplayPipelineAction" [(visible)]="isDisplayPipelineAction"
    [style]="{width: '30vw'}" [draggable]="false" [modal]="true">
    <div class="p-fluid">
        <form [formGroup]="pipelineActionForm" (ngSubmit)="onPipelineActionSubmit()">
            <div class="p-field">
                <p-dropdown formControlName="action" [options]="allowedActionlist" placeholder="Select action"
                    appendTo="body" (onChange)="onActionSelect($event)">
                </p-dropdown>
            </div>

            <div class="p-field" *ngIf="isDisplayPanelList">
                <p-dropdown formControlName="panel" [options]="getPanelsByInterviewType(currentCandidateInterviewType)"
                    [showClear]="true" placeholder="Select Panelist" [filter]="true" [filterBy]="'label'"
                    appendTo="body">
                </p-dropdown>
            </div>

            <div class="p-field">
                <label for="comment">Comment</label>
                <textarea rows="5" cols="30" pInputTextarea [autoResize]="true" id="comment" formControlName="comment"
                    style="min-height: 36px; white-space: pre-wrap;" [maxlength]="textAreaLimit">
                </textarea>
                <div [ngStyle]="{color: 'red', 'margin-top': '5px'}" *ngIf="pipelineActionForm.controls['comment'].hasError('maxlength')">
                    Maximum character limit reached.
                </div>
            </div>

            <div class="p-field">
                <button pButton type="submit" label="Submit" [disabled]="!pipelineActionForm.valid"
                    [style]="{width: '5vw'}">
                </button>
            </div>
        </form>
    </div>
</p-dialog>

<p-dialog *ngIf="isDisplayCommentsAction" [(visible)]="isDisplayCommentsAction"
    [style]="{width: '30vw'}" [draggable]="false" [modal]="true">
    <p-header> Add Comments for {{currentCandidateEmail}}  </p-header>
    <div class="p-fluid">
        <form [formGroup]="commentActionForm" (ngSubmit)="onCommentActionSubmit()">
            <div class="p-field">
                <textarea rows="5" cols="30" pInputTextarea [autoResize]="true" id="comment" formControlName="comment"
                    style="min-height: 36px; white-space: pre-wrap;" [maxlength]="textAreaLimit">
                </textarea>
                <div [ngStyle]="{color: 'red', 'margin-top': '5px'}" *ngIf="commentActionForm.controls['comment'].hasError('maxlength')">
                    Maximum character limit reached.
                </div>
            </div>

            <div class="p-field">
                <button pButton type="submit" label="Submit" [disabled]="!commentActionForm.valid"
                    [style]="{width: '5vw'}">
                </button>
            </div>
        </form>
    </div>
</p-dialog>

<p-dialog header="Candidate Details" *ngIf="isDisplayCandidateInfo" [(visible)]="isDisplayCandidateInfo"
    [style]="{ width: '60vw', 'margin-left': '0px' }" [draggable]="false" [resizable]="false" [modal]="true">
    <div header="Advanced Card" subheader="Card Subheader" style="width: '360px'; margin-left: '80px';">
        <p style="margin-bottom: 3px;"> <b>Name :</b> {{currentCandidateToDisplay.NAME}} </p>
        <p style="margin-bottom: 3px;"> <b>Phone :</b> {{currentCandidateToDisplay.PHONE}} </p>
        <p style="margin-bottom: 3px;"> <b>Email :</b> {{currentCandidateToDisplay.EMAIL}} </p>
        <p style="margin-bottom: 3px;"> <b>Resume : </b> <a [href]="currentCandidateToDisplay.RESUME"
                target="_blank">Resume link</a></p>
        <p style="margin-bottom: 3px;"> <b>Total Score :</b> {{currentCandidateToDisplay.TOTALSCORE}} </p>
        <p style="margin-bottom: 3px;" *ngIf="currentCandidateToDisplay.APTISCORE"> <b>Total Aptitude score :</b>
            {{currentCandidateToDisplay.APTISCORE}} </p>
        <p style="margin-bottom: 3px;" *ngIf="currentCandidateToDisplay.APTISCORE"> <b>Total Programming score :</b>
                {{currentCandidateToDisplay.PROGSCORE}} </p>

    </div>

    <p style="margin: 25px 0px 35px 0px;"> <b>Comments:</b> </p>

    <p-timeline [value]="currentCandidateToDisplay.COMMENTS!">
        <ng-template pTemplate="opposite" let-comment>
            <small class="p-text-secondary">{{comment.COMMENT_DATE}} by {{comment.COMMENT_BY}}</small>
        </ng-template>
        <ng-template pTemplate="content" let-comment>
            <pre style="margin-bottom: 15px; border-bottom: 1px solid #ddd; "><div style="white-space: pre-wrap;">{{comment.COMMENT_MESSAGE}}</div></pre>
        </ng-template>
    </p-timeline>
</p-dialog>