export const environment = {
  production: false,
  BASE_API: "https://dev.api.campus.wginb.com",
  STATIC_API: "https://dev.api.campus.wginb.com/cbdrive/static",
  AUTHENTICATION_API: "https://dev.api.campus.wginb.com/cblogin/login",
  DRIVE_LIST_API: "https://dev.api.campus.wginb.com/cbdrive//drive/list",
  ADD_PANEL_API:"https://dev.api.campus.wginb.com/cbdrive/panel",
  UPDATE_PANEL_API:"https://dev.api.campus.wginb.com/cbdrive/panel/",
  DRIVE_SUMMARY_API: "https://dev.api.campus.wginb.com/cbdrive",
  GET_PANEL_LIST: "https://dev.api.campus.wginb.com/cbdrive/panel/list",
  DELETE_PANEL_API:"https://dev.api.campus.wginb.com/cbdrive/panel/",
  CAMPUS_DRIVE_API: "https://dev.api.campus.wginb.com/cbdrive/drive",
  BULK_UPLOAD_CANDIDATES_API_ENDPOINT_PATH: "/candidate/bulk",
  ALLOWED_FILE_TYPES_FOR_BULK_UPLOAD: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  GET_DRIVE_PANEL_LIST_API_PATH: "/panel/list",
  RESET_PASSWORD: "https://dev.portal.reset.wginb.com"

}
