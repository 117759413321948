<div class="component-with-sidebar">
    <div class="drive-summary-content">
        <div class="drive-summary-content-header" *ngIf="driveSummary">
            {{ collegeName }}  ({{ driveSummary.body.COLLEGE }}) {{driveSummary.body.YEAR}}
        </div>
        <div class="drive-summary-content-card" *ngIf="driveSummary">
            <p-card 
                header="Status: {{ driveSummary.body.STATUS }}"  
                [style]="{ width: '360px' }">
                <ng-template pTemplate="header">
                    <img alt="Card" [src]="getDriveLogo(driveSummary.body.COLLEGE)" />
                </ng-template>
                <ng-template pTemplate="content">
                    Duration: {{ driveSummary.body.FROM_DATE }} - {{driveSummary.body.TO_DATE}} <br>
                    Total Candidates: {{ driveSummary.body.TOTAL_COUNT }} <br>
                    Selected Candidates: {{ driveSummary.body.SELECTED_COUNT }} <br>
                    Number of panels: {{ driveSummary.body.TOTAL_PANEL }} <br><br>
                    Contact Person: {{ driveSummary.body.CONTACT }}
                </ng-template>
                <p>
            </p-card>
        </div>
    </div>
</div>