<div class="login-component">
    <div class="login-img">
        <img src="/assets/images/cb-login-logo.png" class="my-2 mx-6" height="200px" style="margin-top: 40px;">
    </div>
    <div class="login-form">
        <div class="card">
            <div class="card-header">
                Login to Campus Buddy
            </div>
            <div class="card-content">
                <div [formGroup]="form" class="form-content">
                    <label class="form-label">
                        Username
                        <span class="required-marker">*</span>
                    </label>
                    <input class="form-input" type="text" formControlName="username">

                    <label class="form-label">
                        Password
                        <span class="required-marker">*</span>
                    </label>
                    <input class="form-input" type="password" formControlName="password"
                        (keyup)="checkForSubmit($event)">
                    <div>
                        <button class="login-form-btn" type="submit" (click)="onSubmit()">
                            LOGIN
                        </button>
                        <a class="reset-password" target="_blank"
                        pTooltip="MFA should be enabled for resetting your password" matTooltipPosition="after"
                        href="{{ resetPasswordUrl }}">Forgot Password?</a>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>