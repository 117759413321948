import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { PanelsComponent } from './panels/panels/panels.component';
import { CampusDrivesComponent } from './campus-drives/campus-drives/campus-drives.component';
import { ReportsComponent } from './reports/reports/reports.component';
import { DriveSummaryComponent } from './drive-details/drive-summary/drive-summary.component';
import { DriveCandidatesComponent } from './drive-details/drive-candidates/drive-candidates/drive-candidates.component';
import { DriveInterviewsComponent } from './drive-details/drive-interviews/drive-interviews.component';
import { DriveDetailsComponent } from './drive-details/drive-details/drive-details.component';
import { DrivePanelsComponent } from './drive-details/drive-panels/drive-panels.component';
import { DriveCandidatesSelectedComponent } from './drive-details/drive-candidates-selected/drive-candidates-selected.component';
import { DrivePipelineComponent } from './drive-details/drive-pipeline/drive-pipeline.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/auth.guard';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'drives', component: CampusDrivesComponent, canActivate: [AuthGuard] },
  { path: 'panels', component: PanelsComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'drive-details/:driveId', component: DriveDetailsComponent, canActivate: [AuthGuard], children: [
    { path: '', component: DriveSummaryComponent },
    { path: 'drive-summary', component: DriveSummaryComponent },
    { path: 'drive-interviews', component: DriveInterviewsComponent },
    { path: 'drive-candidates', component: DriveCandidatesComponent },
    { path: 'drive-pipeline', component: DrivePipelineComponent },
    { path: 'drive-panels', component: DrivePanelsComponent },
    { path: 'drive-candidates-selected', component: DriveCandidatesSelectedComponent },
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
