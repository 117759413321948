
<div>
    <div class="sidebar-component-header">
      <div class="component-title">
        Selected Candidates List
      </div>
      <div class="component-search-bar">
        <button type="button" style="height: 27px;" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
        <i class="pi pi-refresh" 
        style="color: #367B88; margin-right: 10px; margin-top: 40x; font-size: 20px; cursor: pointer;"
        (click)="getCandidatesList()"></i>
      </div>
    </div> 
    <div>
      <p-table 
        #selectedCandidates
        [columns]="cols" 
        [value]="tableData" 
        styleClass="p-datatable-gridlines" 
        [scrollable]="true" 
        scrollDirection="horizontal"
        [globalFilterFields]="['NAME', 'EMAIL', 'PHONE']">
        <ng-template pTemplate="caption"> 
          <div style="text-align: right"> 
              <span class="p-input-icon-right">
                <i class="pi pi-search" style="color: #367B88"></i>
                <input type="text" pInputText  
                size="30" 
                placeholder="Search"
                (input)= 
"selectedCandidates.filterGlobal($any($event.target).value, 'contains')"  
                style="width:auto"  />
              </span>
          </div> 
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" style="min-width:150px">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              <ng-container *ngIf="col.field === 'actions'">
                <div class="icon-container">
                    <i class="pi pi-comments" style="margin-right: 15%;" pTooltip="Add Comments"
                        (click)="displayaddComments(rowData.EMAIL)"></i>
                    <i class="pi pi-eye" pTooltip="View Details"
                        (click)="displayCandidateInfo(rowData.CANDIDATE_ID)"></i>
                </div>
              </ng-container>
              <ng-container *ngIf="col.field == 'RESUME'">
                <a [href]="rowData[col.field]" target="_blank">Resume link</a>
              </ng-container>
              <ng-container *ngIf="col.field !== 'RESUME' && col.field !== 'actions'">
                {{ rowData[col.field] }}
              </ng-container>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]='cols.length'>No records found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
</div>

<p-dialog *ngIf="isDisplayCommentsAction" [(visible)]="isDisplayCommentsAction"
    [style]="{width: '30vw'}" [draggable]="false">
    <p-header> Add Comments for {{currentCandidateEmail}}  </p-header>
    <div class="p-fluid">
        <form [formGroup]="commentActionForm" (ngSubmit)="onCommentActionSubmit()">
            <div class="p-field">
                <textarea rows="5" cols="30" pInputTextarea [autoResize]="true" id="comment" formControlName="comment"
                    style="min-height: 36px; white-space: pre-wrap;" [maxlength]="textAreaLimit">
                </textarea>
                <div [ngStyle]="{color: 'red', 'margin-top': '5px'}" *ngIf="commentActionForm.controls['comment'].hasError('maxlength')">
                    Maximum character limit reached.
                </div>
            </div>

            <div class="p-field">
                <button pButton type="submit" label="Submit" [disabled]="!commentActionForm.valid"
                    [style]="{width: '5vw'}">
                </button>
            </div>
        </form>
    </div>
</p-dialog>

<p-dialog header="Candidate Details" *ngIf="isDisplayCandidateInfo" [(visible)]="isDisplayCandidateInfo"
    [style]="{ width: '60vw', 'margin-left': '0px' }" [draggable]="false" [resizable]="false">
    <div header="Advanced Card" subheader="Card Subheader" style="width: '360px'; margin-left: '80px';">
        <p style="margin-bottom: 3px;"> <b>Name :</b> {{currentCandidateToDisplay.NAME}} </p>
        <p style="margin-bottom: 3px;"> <b>Phone :</b> {{currentCandidateToDisplay.PHONE}} </p>
        <p style="margin-bottom: 3px;"> <b>Email :</b> {{currentCandidateToDisplay.EMAIL}} </p>
        <p style="margin-bottom: 3px;"> <b>Resume : </b> <a [href]="currentCandidateToDisplay.RESUME"
                target="_blank">Resume link</a></p>
        <p style="margin-bottom: 3px;"> <b>Total Score :</b> {{currentCandidateToDisplay.TOTALSCORE}} </p>
        <p style="margin-bottom: 3px;" *ngIf="currentCandidateToDisplay.APTISCORE"> <b>Total Aptitude score :</b>
            {{currentCandidateToDisplay.APTISCORE}} </p>
            
        <p style="margin-bottom: 3px;" *ngIf="currentCandidateToDisplay.APTISCORE"> <b>Total Programming score :</b>
          {{currentCandidateToDisplay.PROGSCORE}} </p>
    </div>

    <p style="margin: 25px 0px 35px 0px;"> <b>Comments:</b> </p>

    <p-timeline [value]="currentCandidateToDisplay.COMMENTS!">
        <ng-template pTemplate="opposite" let-comment>
            <small class="p-text-secondary">{{comment.COMMENT_DATE}} by {{comment.COMMENT_BY}}</small>
        </ng-template>
        <ng-template pTemplate="content" let-comment>
            <pre style="margin-bottom: 15px; border-bottom: 1px solid #ddd; "><div style="white-space: pre-wrap;">{{comment.COMMENT_MESSAGE}}</div></pre>
        </ng-template>
    </p-timeline>
</p-dialog>