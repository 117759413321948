
<div class="header-main">
    <p-menubar>
        <ng-template pTemplate="start">
            <img src="/assets/images/cb-header.svg" height="150px" class="ml-2 mt-1" />
        </ng-template>
        <ng-template pTemplate="end">
            <span style="color:#ADADAD; margin-right:15px; font-size: 16px;" *ngIf="authStatus">{{ username }}</span>
            <i class="pi pi-sign-out" *ngIf="authStatus" (click)="logout()" style="font-size: 1rem"></i>
        </ng-template>
    </p-menubar>
</div>
<div class="header-nav" *ngIf="authStatus">
    <p-menubar class="nav-menu" [model]="menuItems">
    </p-menubar>
</div>

