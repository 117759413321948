import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  AddDrivePayload,
  AddDriveResponse,
  GetDrivesListResponse,
  GetPanelListResponse,
  GetStaticDataResponse,
  AddPanelRequest,
  UpdatePanelRequestBody,
  ApiResponse,
  UpdateDrivePayload,
  GetDriveResponse,
  UploadBulkCandidatesRequest,
  AddCandidateResponse,
  AddCandidatePayload,
  GetCandidatesListResponse,
  UpdateCandidateRequest,
  GetDrivePanelListResponse,
  AddDrivePanelRequest,
  AddDrivePanelResponse,
  UpdateDrivePanelRequest,
  DeleteCommonReponse,
  GetDriveInterviewListResponse,
  GetCandidateDetailsResponse,
  AddInterviewRequest,
  UpdateCandidateStatusRequest,
  UpdateInterviewRequest
} from './model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CbApiService {

  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  getStaticData(): Observable<GetStaticDataResponse> {
    const url = environment.STATIC_API;
    return this.http.get<GetStaticDataResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getDrivesList(): Observable<GetDrivesListResponse> {
    const url = environment.DRIVE_LIST_API;
    return this.http.get<GetDrivesListResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getPanelList(): Observable<GetPanelListResponse> {
    const url = environment.GET_PANEL_LIST;
    return this.http.get<GetPanelListResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  addPanel(addPanelRequest: AddPanelRequest): Observable<ApiResponse> {
    const url = environment.ADD_PANEL_API;
    return this.http.post<ApiResponse>(url, addPanelRequest, { headers: this.headers }).pipe(catchError(this.handleError))
  }

  updatePanel(updatePanelRequestBody: UpdatePanelRequestBody, email: string): Observable<ApiResponse> {
    const url = environment.UPDATE_PANEL_API + email;
    return this.http.put<ApiResponse>(url, updatePanelRequestBody, { headers: this.headers }).pipe(catchError(this.handleError))
  }

  uploadBulkCandidates(uploadBulkCandidatesRequest: UploadBulkCandidatesRequest, driveId: string): Observable<ApiResponse> {
    const url = `${environment.CAMPUS_DRIVE_API}/${driveId}${environment.BULK_UPLOAD_CANDIDATES_API_ENDPOINT_PATH}`;
    return this.http.post<ApiResponse>(url, uploadBulkCandidatesRequest, { headers: this.headers }).pipe(catchError(this.handleError))
  }

  addDrive(payload: AddDrivePayload): Observable<AddDriveResponse> {
    const url = environment.CAMPUS_DRIVE_API;
    return this.http.post<AddDriveResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  deleteDrive(driveId: string): Observable<ApiResponse> {
    const url = environment.CAMPUS_DRIVE_API + `/${driveId}`;
    return this.http.delete<ApiResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  updateDrive(driveId: string, payload: UpdateDrivePayload): Observable<ApiResponse> {
    const url = environment.CAMPUS_DRIVE_API + `/${driveId}`;
    return this.http.put<ApiResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  deletePanel(email: string): Observable<ApiResponse> {
    const url = environment.DELETE_PANEL_API + email;
    return this.http.delete<ApiResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getDrive(driveId: string): Observable<GetDriveResponse> {
    const url = environment.CAMPUS_DRIVE_API + `/${driveId}`;
    return this.http.get<GetDriveResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  addCandidate(driveId: string, addCandidatePayload: AddCandidatePayload): Observable<AddCandidateResponse> {
    const url = `${environment.CAMPUS_DRIVE_API}/${driveId}/candidate`;
    return this.http.post<AddCandidateResponse>(url, addCandidatePayload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getCandidatesList(driveId: string, candidateStatus?: string): Observable<GetCandidatesListResponse> {
    let url = `${environment.CAMPUS_DRIVE_API}/${driveId}/candidate/list`;
    if (candidateStatus) {
      url += `?candidate_status=${candidateStatus}`
    }
    return this.http.get<GetCandidatesListResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getCandidateDetails(driveId: string, candidateEmail: string): Observable<GetCandidateDetailsResponse> {
    const url = `${environment.CAMPUS_DRIVE_API}/${driveId}/candidate/${candidateEmail}`;
    return this.http.get<GetCandidateDetailsResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  updateCandidate(payload: UpdateCandidateRequest, driveId: string, email: string): Observable<ApiResponse> {
    const url = environment.CAMPUS_DRIVE_API + `/${driveId}/candidate/${email}`;
    return this.http.put<ApiResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  deleteCandidate(driveId: string, email: string): Observable<ApiResponse> {
    const url = environment.CAMPUS_DRIVE_API + `/${driveId}/candidate/${email}`;
    return this.http.delete<ApiResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }
  addComments(payload: any, driveId: string, email: string): Observable<ApiResponse> {
    const url = environment.CAMPUS_DRIVE_API + `/${driveId}/candidate/${email}`;
    return this.http.put<ApiResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  updateCandidateStatus(payload: UpdateCandidateStatusRequest, driveId: string, email: string): Observable<ApiResponse> {
    const url = environment.CAMPUS_DRIVE_API + `/${driveId}/candidate/${email}`;
    return this.http.put<ApiResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getDrivePanelList(driveId: string): Observable<GetDrivePanelListResponse> {
    const url = `${environment.CAMPUS_DRIVE_API}/${driveId}${environment.GET_DRIVE_PANEL_LIST_API_PATH}`;
    return this.http.get<GetDrivePanelListResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  addDrivePanel(payload: AddDrivePanelRequest, driveId: string): Observable<AddDrivePanelResponse> {
    const url = `${environment.CAMPUS_DRIVE_API}/${driveId}/panel`;
    return this.http.post<AddDrivePanelResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  updateDrivePanel(payload: UpdateDrivePanelRequest, driveId: string, panelId: string): Observable<ApiResponse> {
    const url = `${environment.CAMPUS_DRIVE_API}/${driveId}/panel/${panelId}`;
    return this.http.put<ApiResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  deleteDrivePanel(driveId: string, panelId: string): Observable<DeleteCommonReponse> {
    const url = `${environment.CAMPUS_DRIVE_API}/${driveId}/panel/${panelId}`;
    return this.http.delete<DeleteCommonReponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getDriveInterviewList(driveId: string, interviewType: string, panelId: string = ''): Observable<GetDriveInterviewListResponse> {
    const url = `${environment.CAMPUS_DRIVE_API}/${driveId}/interview/list`;
    const params = new HttpParams()
      .set('interview_type', interviewType)
      .set(`panel_key`, panelId)

    return this.http.get<GetDriveInterviewListResponse>(url, { params, headers: this.headers }).pipe(catchError(this.handleError));
  }

  addInterview(payload: AddInterviewRequest, driveId: string): Observable<ApiResponse> {
    const url = `${environment.CAMPUS_DRIVE_API}/${driveId}/interview`;
    return this.http.post<ApiResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  updateInterview(payload: UpdateInterviewRequest, driveId: string, interviewId: string): Observable<ApiResponse> {
    interviewId = encodeURIComponent(interviewId)
    let url = `${environment.CAMPUS_DRIVE_API}/${driveId}/interview/${interviewId}`;
    return this.http.put<ApiResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  scheduleHeInterview(driveId:string,candidate_id:string,payload:object):Observable<ApiResponse>{
    let url = `${environment.CAMPUS_DRIVE_API}/${driveId}/candidate/${candidate_id}/he`;
    return this.http.post<ApiResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }
  cancelInterview(driveId:string,candidate_id:string,interviewId:string):Observable<ApiResponse>{
    let url=`${environment.CAMPUS_DRIVE_API}/${driveId}/candidate/${candidate_id}/he/${interviewId}`;
    return this.http.delete<ApiResponse>(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Observable<never> {
    const errMsgStatus = error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    const errMsg = error.message ? error.message : errMsgStatus;
    return throwError(() => new Error(errMsg));
  }
}