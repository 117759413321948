import { Component, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { AuthenticationService } from 'src/app/shared/authentication.service';
import { SessionService } from 'src/app/shared/session.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {
  imgSrc: string = "/assets/images/wg-logo-white.svg";
  username = '';
  authStatus = false;

  menuItems = [
    {
      label:'Drives',
      icon:'pi pi-fw pi-calendar-times',
      routerLink: ['/drives']
    },
    {
      label:'Panels',
      icon:'pi pi-fw pi-user',
      routerLink: ['/panels']
    },
    {
      label:'Reports',
      icon:'pi pi-fw pi-chart-bar',
      routerLink: ['/reports']
    },
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private sessionService: SessionService) {}

  ngOnInit(): void {
    this.getUsername();
  }

  getUsername(): void {
    this.sessionService.authStatus.subscribe(() => {
      this.authStatus = this.sessionService.isLoggedIn();
      if (this.authStatus) {
        this.username = this.sessionService.getTokenData('name');
      }
    });
  }

  logout(): void {
    this.authenticationService.logout();
  }
}

