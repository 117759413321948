<div class="app">
    <div class="app-header">
        <app-header></app-header>
    </div>
    <div class="app-content">
        <router-outlet></router-outlet>
        <wg-spinner></wg-spinner>
        <app-toast></app-toast>
    </div>
</div>    
