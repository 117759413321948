import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription, forkJoin } from 'rxjs';

import { CbApiService } from 'src/app/shared/cbapi.service';
import { UserService } from 'src/app/shared/user.service';
import {
  AddDrivePayload,
  AddDriveResponse,
  GetStaticDataResponse,
  GetDrivesListResponse,
  Drive,
  ProcessedDrivesList,
  UpdateDrivePayload,
  ApiResponse
} from 'src/app/shared/model';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';


@Component({
  selector: 'app-campus-drives',
  templateUrl: './campus-drives.component.html',
  styleUrls: ['./campus-drives.component.scss']
})
export class CampusDrivesComponent implements OnInit, OnDestroy {
  addDriveForm!: UntypedFormGroup;
  updateDriveForm!: UntypedFormGroup;

  cols = [
    {
      field: 'serialNumber',
      header: '#'
    },
    {
      field: 'COLLEGE',
      header: 'College'
    },
    {
      field: 'driveDates',
      header: 'Drive Dates'
    },
    {
      field: 'totalCandidates',
      header: 'Total Candidates'
    },
    {
      field: 'candidatesSelected',
      header: 'Candidates Selected'
    },
    {
      field: 'STATUS',
      header: 'Status'
    },
    {
      field: 'actions',
      header: 'Actions'
    }
  ];
  drivesList: Drive[] = [];
  colleges: string[] = [];
  years: string[] = [];
  driveStatus: string[] = [];

  driveIdForDeleteDriveOperation: string = '';
  driveIdForUpdateDriveOperation: string = '';

  textAreaLimit!: number;

  isShowAddDrive: boolean = false;
  isShowDeleteDrive: boolean = false;
  isLoadingData: boolean = false;
  isShowUpdateDrive: boolean = false;
  isShowUpdateDriveStatus: boolean = false;

  getDrivesDataSub!: Subscription;
  addDriveSub!: Subscription;
  getDrivesListSub!: Subscription;
  deleteDriveSub!: Subscription;
  updateDriveSub!: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private cbApiService: CbApiService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    public userService: UserService) { }

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    this.getDrivesData();
    this.initAddDriveForm();
  }

  initAddDriveForm(): void {
    this.addDriveForm = this.fb.group({
      college: [''],
      year: [
        null,
        []
      ],
      fromDate: [
        null,
        []
      ],
      toDate: [
        null,
        []
      ],
      contact: [
        '',
        []
      ],
      comment: [
        '',
        []
      ]
    });
  }


  initUpdateDriveForm(rowData: ProcessedDrivesList): void {
    this.driveIdForUpdateDriveOperation = rowData.DRIVE_ID;
    this.updateDriveForm = this.fb.group({
      college: [rowData.COLLEGE],
      year: [
        rowData.YEAR,
        []
      ],
      fromDate: [
        new Date(rowData.FROM_DATE).toLocaleDateString(),
        []
      ],
      toDate: [
        new Date(rowData.TO_DATE).toLocaleDateString(),
        []
      ],
      contact: [
        rowData.CONTACT,
        []
      ],
      status: [
        rowData.STATUS
      ],
      comment: [
        '',
        [Validators.maxLength(this.textAreaLimit - 1)]
      ]
    });
  }

  getDrivesData(): void {
    this.getDrivesDataSub = forkJoin([
      this.cbApiService.getDrivesList(),
      this.cbApiService.getStaticData(),
    ]).subscribe(
      ([getDrivesListResponse, getStaticDataResponse]) => {
        this.handleGetDrivesDataResponse(getDrivesListResponse, getStaticDataResponse);
      },
      (error) => {
        this.spinnerService.hideSpinner();
      }
    );
  }

  handleGetDrivesDataResponse(getDrivesListResponse: GetDrivesListResponse, getStaticDataResponse: GetStaticDataResponse): void {
    if (getDrivesListResponse?.success && getStaticDataResponse?.success) {
      this.textAreaLimit = getStaticDataResponse.body?.TEXT_AREA_LIMIT as number;
      this.drivesList = getDrivesListResponse.body as Drive[];
      this.colleges = getStaticDataResponse.body?.DRIVE_COLLEGES as string[];
      this.years = getStaticDataResponse.body?.DRIVE_YEARS as string[];
      this.driveStatus = getStaticDataResponse.body?.DRIVE_STATUS as string[];
      this.addDriveForm.get('comment')?.setValidators([Validators.maxLength(this.textAreaLimit - 1)]);
      this.addDriveForm.get('comment')?.updateValueAndValidity();
    }
    this.spinnerService.hideSpinner();
  }

  showAddDriveDialog(): void {
    this.isShowAddDrive = true;
  }

  showUpdateDriveDialog(rowData: ProcessedDrivesList): void {
    this.initUpdateDriveForm(rowData);
    this.isShowUpdateDrive = true;
  }

  showUpdateDriveStatusDialog(rowData: ProcessedDrivesList): void {
    this.initUpdateDriveForm(rowData);
    this.isShowUpdateDriveStatus = true;
  }

  showDeleteDriveDialog(driveData: ProcessedDrivesList): void {
    this.driveIdForDeleteDriveOperation = driveData.DRIVE_ID;
    this.isShowDeleteDrive = true;
  }

  hideDeleteDriveDialog(): void {
    this.isShowDeleteDrive = false;
  }

  getDrivesByYear(year: string): ProcessedDrivesList[] {
    const filteredDrives = this.drivesList.filter((drive) => drive.YEAR === year);
    const processedDrives = filteredDrives.map((drive, index) => ({
      serialNumber: index + 1,
      COLLEGE: drive.COLLEGE,
      driveDates: `${drive.FROM_DATE} - ${drive.TO_DATE}`,
      totalCandidates: drive.TOTAL_COUNT ? drive.TOTAL_COUNT : "0",
      candidatesSelected: drive.SELECTED_COUNT ? drive.SELECTED_COUNT : "0",
      DRIVE_ID: drive.DRIVE_ID,
      STATUS: drive.STATUS,
      TO_DATE: drive.TO_DATE,
      FROM_DATE: drive.FROM_DATE,
      CONTACT: drive.CONTACT,
      YEAR: drive.YEAR,
      COMMENT: drive.COMMENT
    }));

    return processedDrives;
  }

  getDrivesList(): void {
    this.getDrivesListSub = this.cbApiService.getDrivesList()
      .subscribe({
        next: (data: GetDrivesListResponse) => this.handleGetDrivesListResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.toastService.showMessage('Failed to fetch drives data', 'error');
        }
      });
  }

  handleGetDrivesListResponse(data: GetDrivesListResponse): void {
    if (data?.success) {
      this.drivesList = data.body as Drive[];
    }
    this.spinnerService.hideSpinner();
  }

  createAddDrivePayload(): AddDrivePayload {
    const addDrivePayload = {
      COLLEGE: this.addDriveForm.value.college,
      YEAR: this.addDriveForm.value.year,
      FROM_DATE: this.datePipe.transform(this.addDriveForm.value.fromDate, 'MM/dd/yy') as string,
      TO_DATE: this.datePipe.transform(this.addDriveForm.value.toDate, 'MM/dd/yy') as string,
      CONTACT: this.addDriveForm.value.contact,
      COMMENT: this.addDriveForm.value.comment
    }
    return addDrivePayload;
  }

  addDrive(addDrivePayload: AddDrivePayload): void {
    this.addDriveSub = this.cbApiService.addDrive(addDrivePayload)
      .subscribe({
        next: (data: AddDriveResponse) => this.handleAddDriveResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.isShowAddDrive = false;
          this.addDriveForm.reset();
          this.toastService.showMessage('Failed to add drive', 'error');
        }
      });
  }

  handleAddDriveResponse(data: AddDriveResponse): void {
    if (data?.success) {
      this.getDrivesList();
      this.toastService.showMessage('Successfully added the drive', 'success');
    } else {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Failed to add drive', 'error');
    }
    this.isShowAddDrive = false;
    this.addDriveForm.reset();
  }

  onAddDriveFormSubmit(): void {
    const addDrivePayload = this.createAddDrivePayload();
    this.spinnerService.showSpinner();
    this.addDrive(addDrivePayload);
  }

  createUpdateDrivePayload(): UpdateDrivePayload {
    const updateDrivePayload = {
      STATUS: this.updateDriveForm.value.status,
      CONTACT: this.updateDriveForm.value.contact,
      TO_DATE: this.datePipe.transform(this.updateDriveForm.value.toDate, 'MM/dd/yy') as string,
      FROM_DATE: this.datePipe.transform(this.updateDriveForm.value.fromDate, 'MM/dd/yy') as string,
      COMMENT: this.updateDriveForm.value.comment
    }

    return updateDrivePayload;
  }

  onUpdateDriveFormSubmit(): void {
    const updateDrivePayload = this.createUpdateDrivePayload();
    this.spinnerService.showSpinner();
    this.updateDrive(updateDrivePayload);
  }

  updateDrive(updateDrivePayload: UpdateDrivePayload): void {
    this.updateDriveSub = this.cbApiService.updateDrive(this.driveIdForUpdateDriveOperation, updateDrivePayload)
      .subscribe({
        next: (data: ApiResponse) => this.handleUpdateDriveResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.isShowUpdateDrive = false;
          this.toastService.showMessage('Failed to update drive', 'error');
        }
      });
  }

  handleUpdateDriveResponse(data: ApiResponse): void {
    if (data?.success) {
      this.toastService.showMessage('Successfully updated the drive', 'success');
      this.getDrivesList();
    } else {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Failed to update drive', 'error');
    }
    this.isShowUpdateDriveStatus = false;
  }

  deleteDrive(): void {
    if (this.driveIdForDeleteDriveOperation) {
      this.spinnerService.showSpinner();
      this.deleteDriveSub = this.cbApiService.deleteDrive(this.driveIdForDeleteDriveOperation)
        .subscribe({
          next: (data: ApiResponse) => this.handleDeleteDriveResponse(data),
          error: () => {
            this.spinnerService.hideSpinner();
            this.isShowDeleteDrive = false;
            this.toastService.showMessage('Failed to delete drive', 'error');
          }
        });
    }
  }

  handleDeleteDriveResponse(data: ApiResponse): void {
    if (data?.success) {
      this.getDrivesList();
      this.toastService.showMessage('Successfully deleted the drive', 'success');
    } else {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Failed to delete drive', 'error');
    }
    this.isShowDeleteDrive = false;
  }

  ngOnDestroy(): void {
    this.getDrivesDataSub?.unsubscribe();
    this.addDriveSub?.unsubscribe();
    this.getDrivesListSub?.unsubscribe();
    this.deleteDriveSub?.unsubscribe();
    this.updateDriveSub?.unsubscribe();
  }
}
