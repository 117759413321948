<div class="drive-details-component">
    <div class="component-with-sidebar">
        <div class="component-sidebar">
            <p-sidebar 
                [(visible)]="displaySideBar" 
                [showCloseIcon]="false" 
                position="left"
                [modal]="false">
            <ng-template pTemplate="header">
                <a [routerLink]="['/drives']"><i class="pi pi-arrow-left" style="color: #434343"></i></a>
                <p class="sidebar-para">Back to drives List</p>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="flex justify-content-center">
                    <p-menu [model]="sideMenuItems"></p-menu>
                </div>
            </ng-template>
            </p-sidebar>
        </div> 
    </div>
    <div class="component-body">
        <router-outlet></router-outlet>
    </div>
</div>