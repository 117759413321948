import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import jwt_decode from 'jwt-decode';

import { __values } from 'tslib';
import { LoginSessionData } from './model';

@Injectable({ providedIn: 'root' })

export class SessionService {
    authStatus = new BehaviorSubject(false);

    constructor() { }

    updateAuthStatus(status: boolean): void {
        this.authStatus.next(status);
    }

    setSessionObject(sessionData: LoginSessionData): void {
        sessionStorage.setItem("cb-user-session", JSON.stringify(sessionData));
        this.authStatus.next(true);
    }

    getSessionData(): LoginSessionData {
        const session: LoginSessionData = JSON.parse(sessionStorage.getItem('cb-user-session')!);
        return session;
    }

    getTokenData(value: keyof LoginSessionData | string): string {
        const session: LoginSessionData = JSON.parse(sessionStorage.getItem('cb-user-session')!);
        const tokenData: any = jwt_decode(session.idtoken);
        return tokenData[value];
    }

    isLoggedIn(): boolean {
        const session: LoginSessionData = JSON.parse(sessionStorage.getItem('cb-user-session')!);
        if (session === null) {
            return false;
        } else {
            const value: any = "exp";
            const timeExpired = new Date().getTime() > (parseInt(this.getTokenData(value), 10)) * 1000;
            return !timeExpired;
        }
    }

    clearSession(): void {
        sessionStorage.removeItem('cb-user-session');
        this.authStatus.next(false);
    }
}
