import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LoginRequest, LoginSessionData } from './model';
import { SessionService } from './session.service';

export const InterceptorSkip = 'X-Skip-Interceptor';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  onFormReset = new Subject<void>();

  headers = new HttpHeaders({ 'X-Skip-Interceptor': '' });

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private router: Router) { }

  login(payload: LoginRequest): Observable<LoginSessionData> {
    const url: string = environment.AUTHENTICATION_API;
    return this.http.post<LoginSessionData>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  logout(): void {
    this.sessionService.clearSession();
    this.router.navigate(['/login']);
  }
  
  private handleError(error: any): Observable<never> {
    const errMsgStatus = error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    const errMsg = error.message ? error.message : errMsgStatus;
    return throwError(() => new Error(errMsg));
  }
}
