import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';

import { Subscription } from 'rxjs';

import { CbApiService } from '../../shared/cbapi.service'
import { AddPanelRequest, GetPanelListResponse, PanelDetails, UpdatePanelRequestBody, ApiResponse } from 'src/app/shared/model';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { UserService } from 'src/app/shared/user.service';
@Component({
  selector: 'app-panels',
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.scss']
})
export class PanelsComponent implements OnInit, OnDestroy {

  isShowAddPanel: boolean = false;
  isShowDeletePanel: boolean = false;
  isShowEditPanel: boolean = false;

  emailToDelete: string = "";

  editPanelForm!: UntypedFormGroup;
  addPanelForm!: UntypedFormGroup;


  updatePanelSub!: Subscription;
  deletePanelSub!: Subscription;
  addPanelSub!: Subscription;
  getPanelSub!: Subscription;

  tableData: any[] = [];
  cols = [
    {
      field: 'SERIALNUMBER',
      header: '#'
    },
    {
      field: 'NAME',
      header: 'Panel Name'
    },
    {
      field: 'EMAIL',
      header: 'Panel email'
    },
    {
      field: 'MOBILE',
      header: 'Panel Mobile'
    },
    {
      field: 'ACTIONS',
      header: 'Actions'
    }
  ];

  constructor(
    private fb: UntypedFormBuilder, 
    private cbApiService: CbApiService, 
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    public userService:UserService) { }

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    this.initAddPanelForm();
    this.fetchPanelList();
  }

  showAddPanelDialog(): void {
    this.initAddPanelForm()
    this.isShowAddPanel = true;
  }

  initAddPanelForm(): void {
    this.addPanelForm = this.fb.group({
      panelName: [
        '',
        []
      ],
      panelEmail: [
        '',
        []
      ],
      panelMobile: [
        '',
        []
      ],
    });
  }

  onSubmit(): void {
    this.spinnerService.showSpinner();
    const addPanelRequest: AddPanelRequest = {
      MOBILE: this.addPanelForm.value.panelMobile,
      NAME: this.addPanelForm.value.panelName,
      EMAIL: this.addPanelForm.value.panelEmail
    }
    this.addPanelSub = this.cbApiService.addPanel(addPanelRequest).subscribe({
      next: (data: any) => {
        this.isShowAddPanel = false;
        this.addPanelForm.reset();
        this.fetchPanelList();
      },
      error: () => {
        this.isShowAddPanel = false;
        this.toastService.showMessage('Failed to add panel', 'error');
        this.addPanelForm.reset();
        this.spinnerService.hideSpinner();
      }
    })
  }

  fetchPanelList(): void {
    this.tableData = [];
    this.spinnerService.showSpinner();
    this.getPanelSub = this.cbApiService.getPanelList().subscribe({
      next: (data: GetPanelListResponse) => {
        this.mapPanelList(data);
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch panel list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  actionClick(event: number, rowData: PanelDetails): void {
    if (event == 0) {
      this.emailToDelete = rowData.EMAIL!
      this.isShowDeletePanel = true;
    }
    else if (event == 1){
      this.initEditPanelForm(rowData);
      this.isShowEditPanel = true;
    }
  }

  initEditPanelForm(panel: PanelDetails): void {
    this.editPanelForm = this.fb.group({
      panelName: new UntypedFormControl(panel.NAME, Validators.required),
      panelEmail: new UntypedFormControl(panel.EMAIL, Validators.required),
      panelMobile: new UntypedFormControl(panel.MOBILE)
    });
  }

  dismissDeletePanel(): void {
    this.isShowDeletePanel = false;
  }

  dismissEditPanel(): void {
    this.isShowEditPanel = false;
  }

  deletePanel(): void {
    this.spinnerService.showSpinner();
    this.deletePanelSub = this.cbApiService.deletePanel(this.emailToDelete).subscribe({
      next: (data: ApiResponse) => {
        this.isShowDeletePanel = false;
        this.fetchPanelList();
      },
      error: () => {
        this.toastService.showMessage('Failed to delete panel', 'error');
        this.spinnerService.hideSpinner();
      }
    })
  }

  updatePanel(): void {
    this.spinnerService.showSpinner();
    
    const updatePanelRequest: UpdatePanelRequestBody = {
      NAME: this.editPanelForm.value.panelName,
      MOBILE: this.editPanelForm.value.panelMobile ? this.editPanelForm.value.panelMobile : '',
    }

    this.updatePanelSub = this.cbApiService.updatePanel(updatePanelRequest, this.editPanelForm.value.panelEmail).subscribe({
      next: (data: ApiResponse) => {
        this.isShowEditPanel = false;
        this.fetchPanelList();
      },
      error: () => {
        this.toastService.showMessage('Failed to update panel', 'error');
        this.spinnerService.hideSpinner();
      }
    })
  }

  mapPanelList(data: GetPanelListResponse): void {
    if (data.success) {
      data.body?.map((panel, index) => {
        const panel_table = {
          "MOBILE": panel.MOBILE,
          "NAME": panel.NAME,
          "EMAIL": panel.EMAIL,
          "ACTIONS": ['pi pi-trash', 'pi pi-pencil'],
          "SERIALNUMBER": index + 1
        }
        this.tableData.push(panel_table)
      })
    }

  }

  ngOnDestroy(): void {
    this.updatePanelSub?.unsubscribe();
    this.deletePanelSub?.unsubscribe();
    this.getPanelSub?.unsubscribe();
    this.addPanelSub?.unsubscribe();
  }
}
