<div class="sidebar-component-header">
    <div class="component-title">
      Panel list
    </div>
    <div class="component-search-bar"  *ngIf="this.userService.IsAdmin()">
      <p-button 
        style = "margin-right:5px;"
        class="add-panel-btn" 
        label="Add Panel"
        (onClick)="showAddPanelDialog()">
        </p-button>
      <span class="p-input-icon-right">
        <i class="pi pi-search" style="color: #367B88"></i>
        <input type="text" pInputText />
      </span>
    </div>
</div>

<div>
    <p-accordion>
        <ng-container *ngFor="let type of interviewTypes">
            <p-accordionTab [header]="type.label!">
            <p-table [columns]="cols" [value]="getPanelsByInterviewType(type.value)" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{ col.header }}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                        <td *ngFor="let col of columns;" >
                            <ng-container *ngIf="col.field === 'actions' && this.userService.IsAdmin()">
                                <div class="icon-container">
                                  <i class="pi pi-pencil" (click)="showUpdatePanelDialog(rowData)"></i>
                                  <i class="pi pi-trash" (click)="showDeletePanelDialog(rowData)"></i> 
                                </div>
                              </ng-container>
                            <ng-container *ngIf="col.field === 'ID'">
                                {{  rowData[col.field] }}
                             </ng-container>
                            <ng-container *ngIf="!(['actions', 'ID']).includes(col.field)">
                              {{ rowData[col.field] }}
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                      <td [attr.colspan]='cols.length'>No records found.</td>
                    </tr>
                </ng-template>
            </p-table>
            </p-accordionTab>
        </ng-container>
    </p-accordion>
</div>

<p-dialog header="Add Panel" [(visible)]="isShowAddPanel" [style]="{width: '30vw'}" [draggable]="false">
    <div class="p-fluid">
        <form [formGroup]="addPanelForm" (ngSubmit)="onAddPanelFormSubmit()">
            <div class="p-field">
                <p-dropdown 
                    formControlName="firstPanelist"
                    [options]="firstPanelList"
                    placeholder="Select Panelist 1"
                    [itemSize]="3" 
                    [filter]="true"
                    [filterBy]="'label'"
                    (onChange)="onFirstPanelSelected($event)"
                    appendTo="body">
                </p-dropdown>
            </div>
            
            <div class="p-field">
                <p-dropdown 
                    formControlName="secondPanelist" 
                    [options]="secondPanelList"
                    [showClear]="true"
                    placeholder="Select Panelist 2"
                    [filter]="true"
                    [filterBy]="'label'"
                    (onChange)="onSecondPanelSelected($event)"
                    appendTo="body">
                </p-dropdown>
            </div>
          
            <div class="p-field">
                <input
                    id="firstInterview"
                    type="checkbox"
                    formControlName="firstInterview"
                />
                <label class="checkbox-label" for="firstInterview">Interview 1</label>
                <br>
            </div>

            <div class="p-field">
                <input
                    id="secondInterview"
                    type="checkbox"
                    formControlName="secondInterview"
                />
                <label class="checkbox-label" for="secondInterview">Interview 2</label>
            </div>

            <div class="p-field">
                <input
                    id="managerialInterview"
                    type="checkbox"
                    formControlName="managerialInterview"
                />
                <label class="checkbox-label" for="managerialInterview">Interview M</label>
            </div>

            <div class="p-field">
            <button 
                pButton 
                type="submit" 
                label="Submit" 
                [disabled]="!addPanelForm.valid" 
                [style]="{width: '5vw'}">
            </button>
            </div>
        </form>
      </div>      
</p-dialog>

<p-dialog header="Update Panel" *ngIf="isShowUpdatePanel" [(visible)]="isShowUpdatePanel" [style]="{width: '30vw'}" [draggable]="false">
    <div class="p-fluid">
        <form [formGroup]="updatePanelForm" (ngSubmit)="onUpdatePanelFormSubmit()">
            <div class="p-field">
                <p-dropdown 
                    formControlName="firstPanelist"
                    [options]="firstPanelList"
                    placeholder="Select Panelist 1"
                    [itemSize]="3" 
                    [filter]="true"
                    [filterBy]="'label'"
                    (onChange)="onFirstPanelSelected($event)"
                    appendTo="body">
                </p-dropdown>
            </div>
            
            <div class="p-field">
                <p-dropdown 
                    formControlName="secondPanelist" 
                    [options]="secondPanelList"
                    [showClear]="true"
                    placeholder="Select Panelist 2"
                    [filter]="true"
                    [filterBy]="'label'"
                    (onChange)="onSecondPanelSelected($event)"
                    appendTo="body">
                </p-dropdown>
            </div>
          
            <div class="p-field">
                <input
                    id="firstInterview"
                    type="checkbox"
                    formControlName="firstInterview"
                />
                <label class="checkbox-label" for="firstInterview">Interview 1</label>
                <br>
            </div>

            <div class="p-field">
                <input
                    id="secondInterview"
                    type="checkbox"
                    formControlName="secondInterview"
                />
                <label class="checkbox-label" for="secondInterview">Interview 2</label>
            </div>

            <div class="p-field">
                <input
                    id="managerialInterview"
                    type="checkbox"
                    formControlName="managerialInterview"
                />
                <label class="checkbox-label" for="managerialInterview">Interview M</label>
            </div>
            
            <div class="p-field">
            <button 
                pButton 
                type="submit" 
                label="Submit" 
                [disabled]="!updatePanelForm.valid" 
                [style]="{width: '5vw'}">
            </button>
            </div>
        </form>
      </div>      
</p-dialog>

<p-dialog header="Delete Drive Panel" *ngIf="isShowDeleteDrivePanel" [(visible)]="isShowDeleteDrivePanel" [style]="{width: '30vw'}" [draggable]="false">
    <div class="p-fluid">
      <form (ngSubmit)="deleteDrivePanel()">
        <div style="margin-bottom: 20px;">
          <label for="panelName">Are you sure you want to delete the panel ?</label>
        </div>
        <p-button type="submit" label="Delete"  [style]="{width: '5vw',margin:'0 20px 0 0'}"></p-button>
        <p-button type="button" label="Cancel" (click)="dismissDeleteDrivePanel()"  class="p-cancel-btn"></p-button>
      </form>
    </div>
  </p-dialog>