import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription, forkJoin } from 'rxjs';

import { CbApiService } from 'src/app/shared/cbapi.service';
import { DriveData, GetDriveResponse, GetStaticDataResponse } from 'src/app/shared/model';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';


@Component({
  selector: 'app-drive-summary',
  templateUrl: './drive-summary.component.html',
  styleUrls: ['./drive-summary.component.scss']
})
export class DriveSummaryComponent implements OnInit, OnDestroy {

  driveId: string = '';
  collegeName: string = '';

  getDrivesDataSub!: Subscription;

  driveSummary!: GetDriveResponse;
  
  driveLogos!: DriveData;
  collegeNames!: DriveData;

  constructor(
    private cbApiService: CbApiService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    this.getDriveId();
    this.getDrivesData();
  }

  getDriveId(): void {
    this.route.parent?.params.subscribe(params => {
      this.driveId = params['driveId'];
    });
  }

  getDrivesData(): void {
    this.getDrivesDataSub = forkJoin([
      this.cbApiService.getDrive(this.driveId),
      this.cbApiService.getStaticData(),
    ]).subscribe(
      ([getDrivesSummaryResponse, getStaticDataResponse]) => {
        this.handleGetDrivesDataResponse(getDrivesSummaryResponse, getStaticDataResponse);
      },
      (error) => {
        this.toastService.showMessage('Failed to fetch drive summary', 'error');
        this.spinnerService.hideSpinner();
      }
    );
  }

  handleGetDrivesDataResponse(getDrivesSummaryResponse: GetDriveResponse, getStaticDataResponse: GetStaticDataResponse): void {
    if (getDrivesSummaryResponse?.success && getStaticDataResponse?.success) {
      this.driveSummary = getDrivesSummaryResponse;

      if (!this.driveSummary.body.TOTAL_COUNT) {
        this.driveSummary.body.TOTAL_COUNT = "0"
      }
      if (!this.driveSummary.body.SELECTED_COUNT) {
        this.driveSummary.body.SELECTED_COUNT = "0"
      }
      if (!this.driveSummary.body.TOTAL_PANEL) {
        this.driveSummary.body.TOTAL_PANEL = "0"
      }
      this.collegeNames = getStaticDataResponse.body?.DRIVE_COLLEGE_NAMES as DriveData;
      this.collegeName =  this.collegeNames[this.driveSummary.body?.COLLEGE];
      this.driveLogos = getStaticDataResponse.body?.DRIVE_LOGOS as DriveData;
    } else {
      this.toastService.showMessage('Failed to fetch drive summary', 'error');
    }
    this.spinnerService.hideSpinner();
  }

  getDriveLogo(collegeName: string): string {
    let noImageLink = '/assets/images/no-image.jpg';
    
    if (this.driveLogos) {
      return this.driveLogos[collegeName] ? this.driveLogos[collegeName] : noImageLink;
    }

    return noImageLink;
  }

  ngOnDestroy(): void {
    this.getDrivesDataSub?.unsubscribe();
  }
}
