import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CbApiService } from 'src/app/shared/cbapi.service';
import { DrivePanelList, GetDrivePanelListResponse, InterviewPanelDetails, GetDriveInterviewListResponse, GetCandidateDetailsResponse, CandidateDetailsWithComments, CandidateInfoToDisplay, CommentDetailToDisplay, DriveInterviewDetails, GetStaticDataResponse, PanelInterviewActionsDict, DrivePanelDetails, AddInterviewRequest, ApiResponse, UpdateCandidateStatusRequest, UpdateInterviewRequest } from 'src/app/shared/model';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-drive-interviews',
  templateUrl: './drive-interviews.component.html',
  styleUrls: ['./drive-interviews.component.scss']
})
export class DriveInterviewsComponent {

  pipelineActionForm!: UntypedFormGroup;
  commentActionForm!: UntypedFormGroup;

  driveId: string = '';
  currentInterviewType: string = '';
  currentPanelId: string = '';
  currentCandidateInterviewType: string = ''
  currentCandidateStatus: string = ''
  currentCandidateEmail: string = ''
  currentInterviewId: string = ''
  isDisplayCandidateInfo: boolean = false;
  isDisplayPipelineAction: boolean = false;
  isDisplayPanelList: boolean = false;
  isDisplayCommentsAction: boolean = false;
  currentCandidateToDisplay!: CandidateInfoToDisplay;
  currentInterviewListByPanelId!: DriveInterviewDetails[];
  candidateStatusList: string[] = [];
  panelInterviewActionList: PanelInterviewActionsDict = {};
  allowedActionlist: SelectItem[] = [];
  masterDrivePanelList!: DrivePanelList;

  getPanelSub!: Subscription;
  getCandidateSub!: Subscription;
  getStaticDataSub!: Subscription;
  addInterviewSub!: Subscription;
  updateCandidateSub!: Subscription;

  textAreaLimit: number = 0;

  interviewTypes: SelectItem<string>[] = []
  interviewPanelDetails: InterviewPanelDetails[] = [];


  constructor(
    private route: ActivatedRoute,
    private cbApiService: CbApiService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    this.initializeInterviewTypes();
    this.getDriveId();
    this.getPipelineData();
    this.getDrivePanelList();
  }

  initializeInterviewTypes() {
    this.interviewTypes = [
      {
        label: "Interview 1", value: "INT_1"
      },
      {
        label: "Interview 2", value: "INT_2"
      },
      {
        label: "Managerial Interview", value: "INT_M"
      }
    ]
  }

  cols = [
    {
      field: 'PANEL_ID',
      header: '#'
    },
    {
      field: 'CANDIDATE_ID',
      header: 'Candidate ID'
    },
    {
      field: 'NAME',
      header: 'Name'
    },
    {
      field: 'STATUS',
      header: 'Status'
    },
    {
      field: 'RESUME',
      header: 'LINKS'
    },
    {
      field: 'PHONE',
      header: 'PHONE'
    },
    {
      field: 'COLLEGE',
      header: 'COLLEGE'
    },
    {
      field: 'actions',
      header: 'Actions'
    }
  ];

  interviewTypeIndex: SelectItem<number>[] = [
    {
      label: 'INT_1',
      value: 0
    },
    {
      label: 'INT_2',
      value: 1
    },
    {
      label: 'INT_M',
      value: 2
    }
  ]

  getDriveId(): void {
    this.route.parent?.params.subscribe(params => {
      this.driveId = params['driveId'];
    });
  }

  onBaseTabOpen(event: any) {
    this.currentInterviewType = this.interviewTypeIndex.find(type => type.value == event.index)?.label!;
  }

  onBaseTabClose() {
    this.currentInterviewListByPanelId = []
  }

  onChildTabOpen(event: any) {
    let panel = this.getInterviewPanelByType(this.currentInterviewType);
    this.currentPanelId = panel[event.index].ID!;
    this.initializeDisplayInterviewListByPanel(this.currentInterviewType, this.currentPanelId)
  }

  onChildTabClose() {
    this.currentInterviewListByPanelId = []
  }

  initPipelineActionForm(): void {
    this.pipelineActionForm = this.fb.group({
      action: [
        '',
        [Validators.required]
      ],
      panel: [
        '',
        []
      ],
      comment: [
        '',
        [Validators.required, Validators.maxLength(this.textAreaLimit - 1)]
      ]
    });
  }

  exportExcel(file_name:string) {
    let headers = this.getHeadersForExcel();
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.currentInterviewListByPanelId, { header: headers });
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, file_name);
    });
  }

  getHeadersForExcel() {
    let headers = ['NAME', 'EMAIL', 'PHONE', 'RESUME', 'BRANCH', 'ROLLNO', 'COLLEGE', 'STATUS', 'MAXSCORE', 'GRADYEAR', 'PROGSCORE', 'COMMENT', 'LOCATION', 'DRIVE_ID', 'DEGREE', 'CANDIDATE_ID', 'TOTALSCORE', 'APTISCORE']
    return headers

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' +this.driveId+'_'+ new Date().getTime() + EXCEL_EXTENSION);
  }
  getPanelsByInterviewType(type: string): SelectItem[] {
    let interviewPanelListByType: SelectItem[] = [];

    this.interviewPanelDetails.filter(panel =>
      panel.INTERVIEW_TYPE == type).forEach(panel =>
        interviewPanelListByType.push({
          label: panel.ID,
          value: panel.ID
        }));

    return interviewPanelListByType;
  }

  onActionSelect(event: any) {
    if (event.value.endsWith('SCHD') && !event.value.startsWith('INT_1')) {
      this.isDisplayPanelList = true;
    }
    else {
      this.isDisplayPanelList = false;
    }
  }

  onPipelineActionSubmit(): void {
    this.spinnerService.showSpinner();
    let action = this.pipelineActionForm.value.action;

    if (action.endsWith('SCHD')) {
      let interviewType: string = ''

      let splitted_action = action.split('_')
      interviewType = `${splitted_action[0]}_${splitted_action[1]}`

      let panel = this.pipelineActionForm.value.panel;

      if (panel) {
        this.scheduleCandidateInterview(interviewType, panel, this.currentCandidateEmail);
        return;
      }
    }
    else {
      this.updateCandidateStatus(action, this.pipelineActionForm.value.comment, this.currentInterviewId)
    }
  }

  
  refreshInterviewDetails(interviewType: string = this.currentInterviewType, panelId: string = this.currentPanelId): void {
    if (this.currentInterviewType && this.currentPanelId){
      this.spinnerService.showSpinner();
      this.initializeDisplayInterviewListByPanel(interviewType, panelId)
    }
  }

  onCommentActionSubmit(): void {
    this.spinnerService.showSpinner();
    var comments = this.commentActionForm.value.comment
    var updateCandidatePayload = {
      "COMMENT": comments
    }
    this.updateCandidateSub = this.cbApiService.addComments
      (updateCandidatePayload, this.driveId, this.currentCandidateEmail)
      .subscribe({
        next: (data: ApiResponse) => this.handleUpdateCandidateResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.isDisplayCommentsAction = false;
          this.toastService.showMessage('Failed to update candidate', 'error');
        }
      });
  }

  handleUpdateCandidateResponse(data: ApiResponse): void {
    if (data?.success) {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Successfully updated the candidate', 'success');

    } else {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Failed to update candidate', 'error');
    }
    this.isDisplayCommentsAction = false;
  }


  updateCandidateStatus(updated_status: string, comment: string, interviewId: string): void {
    let updateCandidateStatusRequest: UpdateInterviewRequest = {
      STATUS: updated_status,
      COMMENTS: comment
    }

    this.updateCandidateSub = this.cbApiService.updateInterview(updateCandidateStatusRequest, this.driveId, interviewId).subscribe({
      next: (data: ApiResponse) => {
        if (data?.success) {
          this.toastService.showMessage('Successfully updated the status.', 'success');
        } else {
          this.toastService.showMessage('Failed to update status.', 'error');
        }
        this.isDisplayPipelineAction = false;
        this.spinnerService.hideSpinner();
        this.refreshInterviewDetails();
      },
      error: () => {
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
      }
    });
  }



  getDrivePanelList(): void {
    this.getPanelSub = this.cbApiService.getDrivePanelList(this.driveId).subscribe({
      next: (data: any) => {
        this.mapDrivePanelList(data);
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch panel list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  mapDrivePanelList(data: GetDrivePanelListResponse): void {
    if (data.success) {
      this.masterDrivePanelList = data.body as DrivePanelList;
      this.interviewPanelDetails = []

      if (this.masterDrivePanelList.INTERVIEW1_PANEL_LIST) {
        this.masterDrivePanelList.INTERVIEW1_PANEL_LIST.forEach((panel) => {
          this.interviewPanelDetails?.push({
            ID: panel.TYPE,
            INTERVIEW_TYPE: "INT_1",
            FIRST_PANELIST_NAME: panel.INTERVIEWER1_EMAIL,
            SECOND_PANELIST_NAME: panel.INTERVIEWER2_EMAIL,
          })
        }
        );
      }

      if (this.masterDrivePanelList.INTERVIEW2_PANEL_LIST) {
        this.masterDrivePanelList.INTERVIEW2_PANEL_LIST.forEach((panel) => {
          this.interviewPanelDetails?.push({
            ID: panel.TYPE,
            INTERVIEW_TYPE: "INT_2",
            FIRST_PANELIST_NAME: panel.INTERVIEWER1_EMAIL,
            SECOND_PANELIST_NAME: panel.INTERVIEWER2_EMAIL
          })
        }
        );
      }

      if (this.masterDrivePanelList.INTERVIEWM_PANEL_LIST) {
        this.masterDrivePanelList.INTERVIEWM_PANEL_LIST.forEach((panel) => {
          this.interviewPanelDetails?.push({
            ID: panel.TYPE,
            INTERVIEW_TYPE: "INT_M",
            FIRST_PANELIST_NAME: panel.INTERVIEWER1_EMAIL,
            SECOND_PANELIST_NAME: panel.INTERVIEWER2_EMAIL,
          })
        }
        );
      }
    }
  }

  getPanelByPanelId(panelId: string, interviewType: string) {
    if (interviewType == 'INT_1') {
      return this.masterDrivePanelList.INTERVIEW1_PANEL_LIST?.find(panel => panel.TYPE == panelId);
    }
    else if (interviewType == 'INT_2') {
      return this.masterDrivePanelList.INTERVIEW2_PANEL_LIST?.find(panel => panel.TYPE == panelId);
    }
    return null
  }

  scheduleCandidateInterview(interviewType: string, panel: string, candidateEmail: string): void {
    let panelList: DrivePanelDetails[] = [];
    let panelDetail = this.getPanelByPanelId(panel, interviewType)

    if (panelDetail) {
      panelList.push(panelDetail!)
    }

    this.getCandidateSub = this.cbApiService.getCandidateDetails(this.driveId, candidateEmail).subscribe({
      next: (data: GetCandidateDetailsResponse) => {
        let candidateList = [data.body];

        let addInterviewRequest: AddInterviewRequest = {
          CANDIDATE_LIST: candidateList,
          PANEL_LIST: panelList,
          INTERVIEW_TYPE: interviewType
        }

        this.addInterview(addInterviewRequest);
      },
      error: () => {
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
      }
    });
  }

  displayPipelineActionDialog(status: string, candidateEmail: string = '', type: string = ''): void {
    this.initPipelineActionForm()
    this.isDisplayPanelList = false
    this.allowedActionlist = []
    let allowedActions = this.panelInterviewActionList[status]

    allowedActions.forEach(action => {
      if (candidateEmail != '' && action.endsWith('SCHD')) {
        let splitted_action = action.split('_')
        this.currentCandidateInterviewType = `${splitted_action[0]}_${splitted_action[1]}`
        this.isDisplayPanelList = true
      }
      this.allowedActionlist.push({ label: action, value: action })
    })
    this.currentCandidateStatus = status
    this.currentCandidateEmail = candidateEmail
    this.currentInterviewId = type
    this.isDisplayPipelineAction = true;
  }

  displayaddComments(candidateEmail: string = '', type: string = ''): void {
    this.initCommentActionForm()
    this.currentCandidateEmail = candidateEmail
    this.currentInterviewId = type
    this.isDisplayCommentsAction = true
  }

  initCommentActionForm(): void {
    this.commentActionForm = this.fb.group({
      comment: [
        '',
        [Validators.required, Validators.maxLength(this.textAreaLimit - 1)]
      ]
    });

  }


  addInterview(addInterviewRequest: AddInterviewRequest): void {
    this.addInterviewSub = this.cbApiService.addInterview(addInterviewRequest, this.driveId)
      .subscribe({
        next: (data: ApiResponse) => {
          if (data?.success) {
            this.toastService.showMessage('Successfully scheduled the interview.', 'success');
          } else {
            this.toastService.showMessage('Failed to schedule interview', 'error');
          }
          this.isDisplayPipelineAction = false;
          this.spinnerService.hideSpinner();
          this.refreshInterviewDetails();
        },
        error: () => {
          this.isDisplayPipelineAction = false;
          this.toastService.showMessage('Failed to add candidate', 'error');
          this.spinnerService.hideSpinner();
        }
      });
  }

  getInterviewPanelByType(type: string) {
    return this.interviewPanelDetails.filter(panel => panel.INTERVIEW_TYPE == type)
  }

  getPipelineData(): void {
    this.spinnerService.showSpinner()
    this.getStaticDataSub = this.cbApiService.getStaticData()
      .subscribe({
        next: (data: GetStaticDataResponse) => this.handleStaticResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.toastService.showMessage('Failed to fetch static data', 'error');
        }
      });
  }

  handleStaticResponse(data: GetStaticDataResponse): void {
    if (data?.success) {
      this.candidateStatusList = data.body!.CANDIDATE_STATUS_LIST as string[];
      this.panelInterviewActionList = data.body!.PANEL_INTERVIEW_ACTIONS_DICT;
      this.textAreaLimit = data.body!.TEXT_AREA_LIMIT;
    }
    this.spinnerService.hideSpinner();
  }

  displayCandidateInfo(candidate_email: string) {
    this.spinnerService.showSpinner();

    this.getCandidateSub = this.cbApiService.getCandidateDetails(this.driveId, candidate_email).subscribe({
      next: (data: GetCandidateDetailsResponse) => {
        this.mapCandidateDetails(data);
        this.spinnerService.hideSpinner();
        this.isDisplayCandidateInfo = true
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  mapCandidateDetails(data: GetCandidateDetailsResponse) {
    if (data.success) {
      let candidate = data.body;

      const candidateInfo: CandidateInfoToDisplay = {
        PHONE: candidate.PHONE,
        NAME: candidate.NAME,
        EMAIL: candidate.EMAIL,
        ROLLNO: candidate.ROLLNO,
        RESUME: candidate.RESUME,
        APTISCORE: candidate.APTISCORE,
        TOTALSCORE: candidate.TOTALSCORE,
        DEGREE: candidate.DEGREE,
        BRANCH: candidate.BRANCH,
        STATUS: candidate.STATUS,
        COLLEGE: candidate.COLLEGE,
        DRIVE_ID: candidate.DRIVE_ID,
        CANDIDATE_ID: candidate.CANDIDATE_ID,
        PROGSCORE:candidate.PROGSCORE,
        COMMENTS: []
      }
      candidate.COMMENTS.forEach(comment => {

        let splitted_comment_detail = comment.COMMENT.split(":");
        const comment_to_display: CommentDetailToDisplay = {
          COMMENT_DATE: splitted_comment_detail[0] + ':' + splitted_comment_detail[1] + ':' + splitted_comment_detail[2],
          COMMENT_BY: splitted_comment_detail[3].trim(),
          COMMENT_MESSAGE: splitted_comment_detail.slice(4).join(':').trim()
        }

        candidateInfo.COMMENTS!.push(comment_to_display);
      })
      this.currentCandidateToDisplay = candidateInfo;
    }
  }

  initializeDisplayInterviewListByPanel(interviewType: string, panelId: string = '') {
    this.spinnerService.showSpinner();
    this.currentInterviewListByPanelId = [];

    this.getPanelSub = this.cbApiService.getDriveInterviewList(this.driveId, interviewType, panelId).subscribe({
      next: (data: GetDriveInterviewListResponse) => {
        this.currentInterviewListByPanelId = data.body!
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch panel list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  ngOnDestroy(): void {
    this.getPanelSub?.unsubscribe();
    this.getCandidateSub?.unsubscribe();
    this.getStaticDataSub?.unsubscribe();
    this.addInterviewSub?.unsubscribe();
    this.updateCandidateSub?.unsubscribe();
  }
}
