import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { InterceptorSkip } from './authentication.service';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  constructor(private sessionService: SessionService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers && request.headers.has(InterceptorSkip)) {
      const headers = request.headers.delete(InterceptorSkip);
      request = request.clone({ headers });
    } else {
      if (!this.sessionService.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      const idtoken = this.sessionService.getSessionData().idtoken;
      request = request.clone({
        headers: request.headers.set('Authorization', String(idtoken))
      });
    }

    const localEnvironment = window.location.href.indexOf('.com') === -1;
    const serverlessApi = this.isServerlessApi(request.url);

    if (localEnvironment && serverlessApi) {
      request = this.applyCorsPolicy(request);
    }
    return next.handle(request).pipe(catchError(this.handleError));
  }

  private handleError = (error: any) => {
    if (error.status === 403) {
      this.sessionService.clearSession();
      this.router.navigate(['/login']);
    }
    const errMsgStatus = error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    const errMsg = error.message ? error.message : errMsgStatus;
    return throwError(() => new Error(errMsg));
  }
  
  private isServerlessApi(url: string): boolean {
    if (!url) {
      return false;
    }
    if (/api./.test(url)) {
      return true;
    }
    return false;
  }

  private applyCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {
    if (/api/.test(req.url)) {
      return req.clone({
        url: req.url.replace(environment.BASE_API, ''),
      });
    }
    return req;
  }

}
